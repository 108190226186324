import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAwuhkKze-DtmUFqF6i-6mR_jpEmGrmCtA",
  authDomain: "doctrue-application.firebaseapp.com",
  projectId: "doctrue-application",
  storageBucket: "doctrue-application.appspot.com",
  messagingSenderId: "192539325232",
  appId: "1:192539325232:web:47ba16b8ac060816f54ef5",
  measurementId: "G-ND8FWLPBZ9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

export { app, storage };
