import React, { useEffect, useState } from "react";
import { DownArrow, LeftArrowWithTail, Menu } from "../../assets/icons/Icons";
import { DocProfileData } from "../../services/types";
import { useNavigate, useParams } from "react-router-dom";
import ProfilePicture from "../atoms/ProfilePicture";
import Toggle from "react-toggle";
import moment from "moment";
import SwitchBranch from "./modals/SwitchBranch";
import { modalStyles, switchBranch } from "../../assets/styles/modalStyles";
import MobileSidebar from "./MobileSidebar";
import { useHospDocData } from "../../lib/contexts/HospitalDoctorContext";
import ClinicModal from "./modals/HospModal";
import OverflowString from "../atoms/OverflowString";
import { getCheckInDoctor, setCheckInDoctor } from "../../lib/apis/doctor";

const Header = ({
  header,
  value,
  docData,
  data,
}: {
  header?: boolean;
  value?: string;
  docData?: DocProfileData;
  data?: {
    mapping_id: string;
    date: string;
    session: string;
  };
}) => {
  const navigate = useNavigate();
  const { allHospData, hospitalID, hospData, doctorsData } = useHospDocData();
  const params = useParams();
  const mapping_id = params.mapping_id;

  const [switchVisible, setSwitchVisible] = useState(false);
  const [clinicVisible, setClinicVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [available, setAvailable] = useState(false);
  const [hoveredDoctorId, setHoveredDoctorId] = useState("");
  const [checkIndata, setCheckInData] = useState({
    mapping_id: "",
    date: "",
    session: "",
  });

  const docAvail = async () => {
    const res = await getCheckInDoctor(
      checkIndata.mapping_id,
      checkIndata.date,
      checkIndata.session
    );
    if (res?.status === 200) {
      console.log(res.data.result.doc_in);
      setAvailable(res.data.result.doc_in);
    } else {
      setAvailable(false);
    }
  };

  useEffect(() => {
    if (data !== undefined && String(data?.session)) {
      setCheckInData(data);
      docAvail();
    }
  }, [data]);

  if (header)
    return (
      <div className="bg-white h-[64px] px-5 lg:pl-7 lg:pr-11 lg:py-5 flex justify-between items-center border-solid border-b-[1px] border-sbBorder">
        <div className="flex flex-row items-center">
          <button
            className="flex lg:hidden"
            onClick={() => setMenuVisible(true)}
          >
            <Menu />
          </button>
          <p className="font-semibold text-xl ml-3 md:ml-5 lg:ml-0">{value}</p>
        </div>
        <div className="flex flex-row items-center">
          <button
            onClick={() => {
              if (allHospData) {
                if (allHospData.length > 1) {
                  setSwitchVisible(true);
                  setClinicVisible(false);
                } else if (hospData !== undefined) {
                  setSwitchVisible(false);
                  setClinicVisible(true);
                } else {
                  setClinicVisible(false);
                  setSwitchVisible(false);
                }
              }
            }}
            className="flex flex-row items-center ml-4"
          >
            {hospData?.logo === "test" ? (
              <ProfilePicture username={hospData.hospital_name} />
            ) : (
              <img
                className={`w-10 h-10 rounded-full`}
                src={hospData?.logo}
                alt="hospital-logo"
              ></img>
            )}
            <p className="font-medium text-sm ml-2 lg:ml-2 mr-3">
              {hospData ? hospData.hospital_name : ""}
            </p>
            {allHospData && allHospData.length > 1 && <DownArrow />}
          </button>
        </div>
        <SwitchBranch
          modalIsOpen={switchVisible}
          closeModal={() => setSwitchVisible(false)}
          customStyles={switchBranch}
        />
        <ClinicModal
          modalIsOpen={clinicVisible}
          closeModal={() => setClinicVisible(false)}
          customStyles={modalStyles}
        />
        {menuVisible && (
          <MobileSidebar visible={menuVisible} setVisible={setMenuVisible} />
        )}
      </div>
    );

  return (
    <div className="bg-white h-[64px] px-5 lg:pl-7 lg:pr-11 lg:py-5 flex justify-between items-center border-solid border-b-[1px] border-sbBorder">
      <div className="flex flex-row items-center">
        <button className="flex lg:hidden" onClick={() => setMenuVisible(true)}>
          <Menu />
        </button>
        <button
          className="hidden lg:flex"
          onClick={() => navigate("/" + hospitalID + "/doctors")}
        >
          <LeftArrowWithTail />
        </button>
        {docData?.profile_picture === docData?.full_name ? (
          <ProfilePicture
            username={docData?.full_name ? docData?.full_name : ""}
            className={"mx-2"}
          />
        ) : (
          <img
            className={`w-10 h-10 rounded-full mx-2 hidden md:flex`}
            src={docData?.profile_picture}
            alt="doctor"
          ></img>
        )}
        <div className="flex flex-col mx-2 md:ml-0">
          <div className="flex flex-row items-center">
            <p className="font-semibold text-lg">{docData?.full_name}</p>
          </div>
          <p className="text-xs text-docDetail">
            <OverflowString
              text={docData?.qualification + ", " + docData?.specialization}
              length={40}
            />
          </p>
        </div>
        {data &&
          data.date === moment().format("YYYY-MM-DD") &&
          data.session !== "undefined" && (
            <div className="flex flex-col ml-3 md:ml-5">
              <p className="text-xs text-docDetail">Check-In</p>
              <Toggle
                icons={false}
                defaultChecked={available}
                checked={available}
                onChange={async () => {
                  setAvailable(true);
                  const res = await setCheckInDoctor(
                    checkIndata.mapping_id,
                    checkIndata.date,
                    checkIndata.session,
                    true
                  );
                  if (res?.status === 200) console.log(res.data.result);
                }}
                disabled={available}
              />
            </div>
          )}
      </div>
      <div className="hidden md:flex">
        {doctorsData &&
          doctorsData
            .filter((doctor) => doctor.mapping_id !== mapping_id)
            .map((doctor) => (
              <button
                key={doctor.doctor_id}
                onMouseEnter={() => setHoveredDoctorId(doctor.mapping_id)}
                onMouseLeave={() => setHoveredDoctorId("")}
                onClick={() =>
                  navigate(
                    "/" + hospitalID + "/doctors/doc/" + doctor.mapping_id
                  )
                }
                className="flex flex-col items-center"
              >
                {doctor?.profile_picture === doctor?.full_name ? (
                  <ProfilePicture
                    username={doctor?.full_name ? doctor?.full_name : ""}
                    className={"mx-2 w-9 h-9"}
                  />
                ) : (
                  <img
                    className={`w-9 h-9 rounded-full mx-2 hidden md:flex`}
                    src={doctor?.profile_picture}
                    alt="doctor"
                  ></img>
                )}
                {hoveredDoctorId === doctor.mapping_id && (
                  <p className="text-xs text-docDetail mx-1">
                    {doctor.full_name.split(" ")[0]}
                  </p>
                )}
              </button>
            ))}
      </div>
      <div className="flex flex-row items-center">
        <button
          onClick={() => {
            if (allHospData) {
              if (allHospData.length > 1) {
                setSwitchVisible(true);
                setClinicVisible(false);
              } else if (hospData !== undefined) {
                setSwitchVisible(false);
                setClinicVisible(true);
              } else {
                setClinicVisible(false);
                setSwitchVisible(false);
              }
            }
          }}
          className="flex flex-row items-center ml-4"
        >
          {hospData?.logo === "test" ? (
            <ProfilePicture username={hospData.hospital_name} />
          ) : (
            <img
              className={`w-10 h-10 rounded-full`}
              src={hospData?.logo}
              alt="hospital-logo"
            ></img>
          )}
          <p className="font-medium text-sm ml-2 lg:ml-2 mr-3 hidden md:flex">
            {hospData ? hospData.hospital_name : ""}
          </p>
          {allHospData && allHospData.length > 1 && <DownArrow />}
        </button>
      </div>
      <SwitchBranch
        modalIsOpen={switchVisible}
        closeModal={() => setSwitchVisible(false)}
        customStyles={switchBranch}
      />
      <ClinicModal
        modalIsOpen={clinicVisible}
        closeModal={() => setClinicVisible(false)}
        customStyles={modalStyles}
      />
      {menuVisible && (
        <MobileSidebar visible={menuVisible} setVisible={setMenuVisible} />
      )}
    </div>
  );
};

export default Header;
