import React, { useEffect, useState } from "react";
import Header from "../components/organisms/Header";
import Tabs from "../components/molecules/Tabs";
import Table from "../components/organisms/Table";
import { patientHeaders } from "../assets/data/headers";
import Loader from "../components/molecules/Loader";
import { getRouteSegment } from "../lib/utils/funcs";
import { LeftArrow, RightArrow, Search } from "../assets/icons/Icons";
import { useUserData } from "../lib/contexts/UserContext";
import { getPatientsByHospitalID } from "../lib/apis/patient";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import { usePatientBooking } from "../lib/contexts/PatientBookingContext";
import { hitRefreshToken } from "../lib/apis/user";
import { getCookie, setCookie } from "../lib/utils/cookies";
import EditPatientModal from "../components/organisms/modals/EditPatientModal";
import { modalStyles } from "../assets/styles/modalStyles";
import { Patient } from "../lib/utils/types";

const Patients = () => {
  const id = getRouteSegment(1);
  const accessToken = String(getCookie("accessToken"));
  const refreshToken = String(getCookie("refreshToken"));

  const { userData } = useUserData();
  const { hospitalID, setHospitalID } = useHospDocData();
  const { patientsData, setPatientsData } = usePatientBooking();
  console.log("patientsData", patientsData);

  const [visible, setVisible] = useState(false);
  const [patient, setPatient] = useState<Patient>();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const fetchPatientsData = async () => {
    const res = await getPatientsByHospitalID(
      hospitalID,
      page,
      pageSize,
      search
    );
    if (res?.status === 200) setPatientsData(res.data.result);
    else if (res?.status === 401) {
      const refresh_data = await hitRefreshToken(accessToken, refreshToken);
      if (refresh_data?.status === 200) {
        console.log("Refresh");
        setCookie("accessToken", refresh_data.data.result.access_token, 30);
        setCookie("refreshToken", refresh_data.data.result.refresh_token, 30);
        const res = await getPatientsByHospitalID(
          hospitalID,
          page,
          pageSize,
          search
        );
        if (res?.status === 200) setPatientsData(res.data.result);
      }
    }
  };

  useEffect(() => {
    setHospitalID(id);
  }, [id]);

  useEffect(() => {
    fetchPatientsData();
  }, [, userData, page, pageSize, search]);

  const tabsConfig = [
    {
      label: "All Patients",
      count: "(" + (patientsData?.total_distinct_bookings || "0") + ")",
      content:
        patientsData === undefined ||
        patientsData?.booking_list.length === 0 ? (
          <div className="bg-white flex justify-center items-center h-96">
            <p className="text-center">No Patients Found.</p>
          </div>
        ) : (
          <div className="max-h-[65vh] md:max-h-[80vh] lg:max-h-[65vh] overflow-y-auto">
            <Table
              type="patients"
              headers={patientHeaders}
              patientData={patientsData?.booking_list}
              setVisible={setVisible}
              setPatient={setPatient}
            />
          </div>
        ),
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <Header header value={"Patients"} />
      <div className="flex flex-col bg-white rounded-t-lg mt-6 ml-7 mr-8 pt-4 border-[0.5px] border-doctorsBorder">
        <Tabs
          tabsConfig={tabsConfig}
          rightSide={
            <div className="flex items-center text-sm m-3 md:mt-0 md:mb-[7px]">
              <div className="flex items-center rounded-lg border-[0.5px] border-sbBorder w-full bg-white md:mr-5">
                <input
                  className="border-0 p-0 px-4 py-2 border-sbBorder opacity-80 rounded-lg focus:outline-none w-5/6"
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <p className="w-1/6">
                  <Search />
                </p>
              </div>
            </div>
          }
        />
        <div className="flex flex-row justify-end items-center bg-white px-2 py-1 border-[0.5px] border-doctorsBorder">
          <p className="py-2 mr-3 text-sm">Rows per page:</p>
          <select
            className="rounded-lg p-1 border-[0.5px] border-sbBorder focus:outline-none mr-6"
            name="specialization"
            onChange={(e) => setPageSize(Number(e.target.value))}
            defaultValue={5}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
          <p className="mr-3 text-sm">Page {page}</p>
          <button
            className="mr-2"
            onClick={() => page > 1 && setPage(page - 1)}
          >
            <LeftArrow />
          </button>
          <button onClick={() => setPage(page + 1)}>
            <RightArrow />
          </button>
        </div>
      </div>

      <EditPatientModal
        modalIsOpen={visible}
        closeModal={() => setVisible(false)}
        customStyles={modalStyles}
        patient={patient}
        page={page}
        pageSize={pageSize}
        search={search}
      />
      {userData === undefined && <Loader />}
    </div>
  );
};

export default Patients;
