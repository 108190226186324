import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { Option } from "react-dropdown";
import moment from "moment";
import { useParams } from "react-router-dom";
import DarkBlueButton from "../../atoms/buttons/DarkBlueButton";
import WhiteButton from "../../atoms/buttons/WhiteButton";
import CloseButton from "../../atoms/buttons/CloseButton";
import { usePatientBooking } from "../../../lib/contexts/PatientBookingContext";
import {
  addBooking,
  getBookingListByAvailabilityId,
  getSlots,
  getTokens,
} from "../../../lib/apis/booking";
import { createPatient, getPatientByPhoneNo } from "../../../lib/apis/patient";
import { useHospDocData } from "../../../lib/contexts/HospitalDoctorContext";
import { getRouteSegment } from "../../../lib/utils/funcs";
import { useUserData } from "../../../lib/contexts/UserContext";
import { LeftArrow, RightArrow } from "../../../assets/icons/Icons";
import NotFound from "../../atoms/buttons/NotFound";
import SelectSlot from "../../atoms/SelectSlot";
import SelectToken from "../../atoms/SelectToken";
import { estimateVisitTime } from "../../../lib/utils/constants";

const AddBookingModal = ({
  closeModal,
  modalIsOpen,
  customStyles,
  session,
  options,
  queue_type,
  setSession,
  setQueue_type,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
  session: Option | undefined;
  options: Option[] | undefined;
  queue_type: string;
  setSession: React.Dispatch<React.SetStateAction<Option | undefined>>;
  setQueue_type: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { userData } = useUserData();
  const { docDetails } = useHospDocData();
  const { setBookings, SelectedDate, setSelectedDate, setIndex } =
    usePatientBooking();
  const [next, setNext] = useState(false);
  const [patient, setPatient] = useState({
    full_name: "",
    contact_number: "",
    gender: "Male",
    patient_id: "",
    registered: false,
  });
  const [formState, setFormState] = useState({
    comments: "",
    type: "booking",
    currentIndex: 0,
    allSlots: [""],
    slicedSlots: [""],
    allTokens: [0],
    slicedTokens: [0],
  });

  const [bookingSlot, setBookingSlot] = useState<Array<string>>();
  const [token, setToken] = useState<number>();
  const param = useParams();
  const mapping_id = String(param.mapping_id);
  const hospital_id = getRouteSegment(1);

  const showNext = () => {
    // Ensure we don't go beyond the length of the data array
    console.log(formState.currentIndex + 10, formState.allSlots.length);
    if (formState.currentIndex + 10 < formState.allSlots.length) {
      setFormState((prevState) => ({
        ...prevState,
        currentIndex: formState.currentIndex + 10,
      }));
    }
    if (formState.currentIndex + 10 < formState.allTokens.length) {
      setFormState((prevState) => ({
        ...prevState,
        currentIndex: formState.currentIndex + 10,
      }));
    }
  };

  // Function to show the previous 10 items
  const showPrevious = () => {
    // Ensure we don't go below 0
    if (formState.currentIndex - 10 >= 0) {
      setFormState((prevState) => ({
        ...prevState,
        currentIndex: formState.currentIndex - 10,
      }));
    }
  };

  const showSlotsTokens = useCallback(async () => {
    if (!mapping_id || !session) return;
    // console.log(session);
    const [start_time, end_time] = String(session?.label)?.split(" - ");

    const data = {
      mapping_id: mapping_id,
      booked_date: SelectedDate,
      number_of_people: 1,
      availability_id: session.value,
      start_time: moment(start_time, "hh:mmA").format("HH:mm:ss"),
      end_time: moment(end_time, "hh:mmA").format("HH:mm:ss"),
    };
    // console.log(data);
    // const slots_data = await getSlots(data);
    // console.log(slots_data?.data);

    // if (slots_data?.status === 200 && slots_data.data.result.length !== 0) {
    //   const api_data: { start: string; end: string }[] = slots_data.data.result;

    //   // console.log("API Data:", api_data);

    //   const allSlots =
    //     SelectedDate === moment().format("YYYY-MM-DD")
    //       ? api_data
    //           .filter((slot) => moment(slot.end, "HH:mm").isAfter(moment()))
    //           .map((slot) => `${slot.start} - ${slot.end}`)
    //       : api_data.map((slot) => `${slot.start} - ${slot.end}`);

    //   // console.log("All Slots:", allSlots);

    //   const slicedData = allSlots.slice(
    //     formState.currentIndex,
    //     formState.currentIndex + 10
    //   );

    //   // console.log("Sliced Data:", slicedData);

    //   if (allSlots.length !== 0) {
    //     setFormState((prevState) => ({
    //       ...prevState,
    //       allSlots: allSlots,
    //       slicedSlots: slicedData,
    //     }));
    //     setBookingSlot(slicedData.slice(0, 1));
    //   }
    // } else if (slots_data?.data.length === 0) {
    //   setFormState((prevState) => ({
    //     ...prevState,
    //     allSlots: [""],
    //   }));
    // }
    // console.log(data);
    const token_data = await getTokens(data);
    // console.log(token_data?.data);

    if (token_data?.status === 200 && token_data.data.result.length !== 0) {
      const api_data: Array<number> = token_data.data.result;
      const allTokens =
        SelectedDate === moment().format("YYYY-MM-DD") &&
        moment().isAfter(
          moment(String(session?.label).split(" - ")[1], "hh:mmA")
        )
          ? [0]
          : hospital_id === "830b6f09-c99f-40ed-85d2-84e2ae183578" &&
            userData?.user_id !== "ae5b1dd2-b1a3-4969-acee-197d614c5e89"
          ? api_data.filter((token) => token % 2 !== 0)
          : api_data;
      // moment().isAfter(moment(String(session?.label).split(" - ")[1], "hh:mmA"))

      // console.log("allTokens", allTokens);

      const slicedData = allTokens.slice(
        formState.currentIndex,
        formState.currentIndex + 10
      );

      // console.log("slicedData", slicedData);

      if (allTokens.length !== 0) {
        setFormState((prevState) => ({
          ...prevState,
          allTokens: allTokens,
          slicedTokens: slicedData,
        }));
        setToken(slicedData.slice(0, 1)[0]);
        // console.log(slicedData.slice(0, formState.people)[0]);
      }
    }
  }, [mapping_id, session, SelectedDate, formState.currentIndex]);

  useEffect(() => {
    showSlotsTokens();
  }, [modalIsOpen, session, showSlotsTokens]);

  const resetStates = () => {
    setPatient({
      full_name: "",
      contact_number: "",
      gender: "Male",
      patient_id: "",
      registered: false,
    });
    setFormState({
      comments: "",
      type: "booking",
      currentIndex: 0,
      allSlots: [""],
      slicedSlots: [""],
      allTokens: [0],
      slicedTokens: [0],
    });
  };

  const handleTokenBooking = async (patient_id: string) => {
    console.log("token");

    if (
      patient_id !== "" &&
      patient.full_name !== "" &&
      patient.gender !== "" &&
      SelectedDate &&
      session &&
      userData &&
      token
    ) {
      const availability_id = String(session?.value);

      const req = {
        booking_mode: formState.type,
        booked_date: SelectedDate,
        booked_slot_start: String(session?.label)?.split(" - ")[0],
        booked_slot_end: String(session?.label)?.split(" - ")[1],
        booking_session_start_time: String(session?.label)?.split(" - ")[0],
        booking_session_end_time: String(session?.label)?.split(" - ")[1],
        is_confirmed: true,
        symptoms: "test",
        comments: formState.comments,
        availability_id: availability_id,
        hospital_id: hospital_id,
        patient_id: patient_id,
        doctor_id: docDetails?.doctor_id,
        queue_type: queue_type,
        status: formState.type === "walk-in" ? 1 : 0,
        added_by: userData.user_id,
        token_number: token,
      };

      const res = await addBooking(req);

      if (res?.status === 201) {
        resetStates();
        setNext(false);

        toast.success(
          `${
            formState.type === "booking"
              ? "Patient booking added!"
              : "In-clinic patient added!"
          }`
        );

        const booked_data = await getBookingListByAvailabilityId(
          availability_id,
          SelectedDate
        );
        if (booked_data?.status === 200) {
          setBookings(booked_data.data.result);
        }
      }
    } else if (token === null) {
      toast.error("Please select a token.");
    } else {
      toast.error("Invalid inputs.");
    }
  };

  const handleSlotBooking = async (patient_id: string) => {
    console.log("slot");
    if (
      patient_id !== "" &&
      bookingSlot?.length === 1 &&
      patient.full_name !== "" &&
      patient.gender !== "" &&
      SelectedDate &&
      session &&
      userData
    ) {
      const availability_id = String(session?.value);

      for (let index = 0; index < bookingSlot?.length; index++) {
        const slot = bookingSlot[index];

        const req = {
          booking_mode: formState.type,
          booked_date: SelectedDate,
          booked_slot_start: slot.split(" - ")[0] + ":00",
          booked_slot_end: slot.split(" - ")[1] + ":00",
          booking_session_start_time: String(session?.label)?.split(" - ")[0],
          booking_session_end_time: String(session?.label)?.split(" - ")[1],
          is_confirmed: true,
          symptoms: "test",
          comments: formState.comments,
          availability_id: availability_id,
          hospital_id: hospital_id,
          patient_id: patient_id,
          doctor_id: docDetails?.doctor_id,
          queue_type: queue_type,
          status: formState.type === "walk-in" ? 1 : 0,
          added_by: userData.user_id,
        };

        const res = await addBooking(req);

        if (res?.status === 201) {
          resetStates();
          setNext(false);

          toast.success(
            `${
              formState.type === "booking"
                ? "Patient booking added!"
                : "In-clinic patient added!"
            }`
          );

          const booked_data = await getBookingListByAvailabilityId(
            availability_id,
            SelectedDate
          );
          if (booked_data?.status === 200) {
            setBookings(booked_data.data.result);
          }
        }
      }
    } else if (bookingSlot === undefined) {
      toast.error("Please select a slot.");
    } else {
      toast.error("Invalid inputs.");
    }
  };

  const handleSessionBooking = async (patient_id: string) => {
    console.log("session");
    if (
      patient_id !== "" &&
      patient.full_name !== "" &&
      patient.gender !== "" &&
      SelectedDate &&
      session &&
      userData
    ) {
      const availability_id = String(session?.value);

      const req = {
        booking_mode: formState.type,
        booked_date: SelectedDate,
        booked_slot_start: String(session?.label)?.split(" - ")[0],
        booked_slot_end: String(session?.label)?.split(" - ")[1],
        booking_session_start_time: String(session?.label)?.split(" - ")[0],
        booking_session_end_time: String(session?.label)?.split(" - ")[1],
        is_confirmed: true,
        symptoms: "test",
        comments: formState.comments,
        availability_id: availability_id,
        hospital_id: hospital_id,
        patient_id: patient_id,
        doctor_id: docDetails?.doctor_id,
        queue_type: queue_type,
        status: formState.type === "walk-in" ? 1 : 0,
        added_by: userData.user_id,
      };

      const res = await addBooking(req);

      if (res?.status === 201) {
        resetStates();
        setNext(false);

        toast.success(
          `${
            formState.type === "booking"
              ? "Patient booking added!"
              : "In-clinic patient added!"
          }`
        );

        const booked_data = await getBookingListByAvailabilityId(
          availability_id,
          SelectedDate
        );
        if (booked_data?.status === 200) {
          setBookings(booked_data.data.result);
        }
      }
    } else {
      toast.error("Invalid inputs.");
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (
      !next &&
      !isNaN(Number(patient.contact_number)) &&
      String(patient.contact_number).length === 10 &&
      /^[6-9]\d{9}$/.test(patient.contact_number)
    ) {
      //"7760915376"
      // const res = await getPatientByPatientId("713dceb5-9064-4886-a103-5fe4e645efec");
      console.log("1");
      const res = await getPatientByPhoneNo("91" + patient.contact_number);
      if (res?.status === 200) {
        console.log(res.data.result);
        const data = res.data.result[0];
        setPatient((prevState) => ({
          ...prevState,
          full_name: data.full_name.trim(),
          contact_number: data.contact_number,
          gender: data.gender,
          patient_id: data.patient_id,
          registered: true,
        }));
      }
      setNext(true);
    } else if (
      !next &&
      (patient.contact_number === "" ||
        isNaN(Number(patient.contact_number)) ||
        patient.contact_number.length !== 10)
    ) {
      console.log("2");
      toast.error("Please enter a valid phone number.");
    } else if (
      next &&
      patient.registered === true &&
      patient.full_name !== "" &&
      patient.gender !== "" &&
      SelectedDate &&
      session
    ) {
      console.log("3");
      closeModal();
      queue_type === "Token"
        ? handleTokenBooking(patient.patient_id)
        : queue_type === "Session"
        ? handleSessionBooking(patient.patient_id)
        : handleSlotBooking(patient.patient_id);
    } else if (
      next &&
      patient.registered === false &&
      patient.full_name !== "" &&
      patient.gender !== "" &&
      SelectedDate &&
      session
    ) {
      console.log("4");
      closeModal();
      //Create patient then make booking
      const req = {
        full_name: patient.full_name.trim(),
        contact_number: "91" + patient.contact_number,
        gender: patient.gender,
        date_of_birth: moment().format("YYYY-MM-DD"),
      };
      const res = await createPatient(req);
      console.log(res.data.result);
      if (res?.status === 201) {
        setPatient((prevState) => ({
          ...prevState,
          registered: true,
        }));

        queue_type === "Token"
          ? handleTokenBooking(res.data.result.patient_id)
          : queue_type === "Session"
          ? handleSessionBooking(res.data.result.patient_id)
          : handleSlotBooking(res.data.result.patient_id);
      }
    } else if (!next && !/^[6-9]\d{9}$/.test(patient.contact_number)) {
      toast.error("Please enter a valid phone number.");
    } else if (patient.full_name === "") {
      console.log("first");
      toast.error("Please enter patient name.");
    } else if (patient.gender === "") {
      toast.error("Please enter patient gender.");
    } else if (patient.contact_number === "") {
      toast.error("Please enter patient phone number.");
    } else {
      toast.error("Invalid inputs.");
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      {/* Header */}
      <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
        <p className="text-dark text-md">Add Booking</p>
        <CloseButton
          handleClick={() => {
            closeModal();
            resetStates();
            setNext(false);
          }}
        />
      </div>

      {/* Body */}
      <form onSubmit={handleSubmit} className="mb-0">
        <div className="p-0 max-h-96 overflow-y-auto">
          <div className="px-6 py-5 bg-lightGrey">
            {!next ? (
              <div className="flex flex-col w-full">
                <p className="font-light text-modalText text-sm mb-1">
                  Phone Number
                </p>
                <input
                  className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                  placeholder="Enter phone number"
                  value={patient.contact_number}
                  onChange={(e) =>
                    setPatient((prevState) => ({
                      ...prevState,
                      contact_number: e.target.value,
                    }))
                  }
                  minLength={10}
                  maxLength={10}
                  required
                  autoFocus
                />
              </div>
            ) : (
              <>
                {SelectedDate === moment().format("YYYY-MM-DD") && (
                  <div className="flex flex-col w-full mb-3">
                    <p className="font-light text-modalText text-sm mb-1">
                      Booking Type
                    </p>
                    <div className="flex flex-row space-x-3">
                      <button
                        className={`${
                          formState.type === "booking"
                            ? "bg-editBG text-white"
                            : "border-[1px] border-editBG text-editBG hover:bg-purple-400 hover:text-white"
                        } px-4 py-2 rounded-[20px]`}
                        onClick={() =>
                          setFormState((prevState) => ({
                            ...prevState,
                            type: "booking",
                          }))
                        }
                        type="button"
                      >
                        Booking
                      </button>
                      <button
                        className={`${
                          formState.type === "walk-in"
                            ? "bg-editBG text-white"
                            : "border-[1px] border-editBG text-editBG hover:bg-purple-400 hover:text-white"
                        } px-4 py-2 rounded-[20px]`}
                        onClick={() =>
                          setFormState((prevState) => ({
                            ...prevState,
                            type: "walk-in",
                          }))
                        }
                        type="button"
                      >
                        Walk-In
                      </button>
                    </div>
                  </div>
                )}
                <div>
                  <p className="font-light text-modalText text-sm mb-1">
                    Full Name
                  </p>
                  <input
                    className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                    placeholder="Enter full name"
                    type="text"
                    value={patient.full_name}
                    disabled={patient.registered}
                    onChange={(e) =>
                      setPatient((prevState) => ({
                        ...prevState,
                        full_name: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="flex flex-col md:flex-row md:space-x-3 w-full">
                  <div className="mt-3 w-full md:w-1/2">
                    <p className="font-light text-modalText text-sm mb-1">
                      Comments
                    </p>
                    <input
                      className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                      placeholder="Add comments"
                      value={formState.comments}
                      onChange={(e) =>
                        setFormState((prevState) => ({
                          ...prevState,
                          comments: e.target.value,
                        }))
                      }
                      maxLength={50}
                    />
                  </div>
                  <div className="flex flex-row mt-3">
                    <div className="flex flex-col w-full">
                      <p className="font-light text-modalText text-sm mb-1 ">
                        Gender
                      </p>
                      <div className="flex flex-row space-x-3">
                        <button
                          className={`${
                            patient.gender?.toLowerCase() === "male"
                              ? "bg-editBG text-white"
                              : "border-[1px] border-editBG text-editBG hover:bg-purple-400 hover:text-white"
                          } px-4 py-2 rounded-[20px]`}
                          disabled={patient.registered}
                          onClick={() =>
                            setPatient((prevState) => ({
                              ...prevState,
                              gender: "Male",
                            }))
                          }
                          type="button"
                        >
                          Male
                        </button>
                        <button
                          className={`${
                            patient.gender?.toLowerCase() === "female"
                              ? "bg-editBG text-white"
                              : "border-[1px] border-editBG text-editBG hover:bg-purple-400 hover:text-white"
                          } px-4 py-2 rounded-[20px]`}
                          disabled={patient.registered}
                          onClick={() =>
                            setPatient((prevState) => ({
                              ...prevState,
                              gender: "Female",
                            }))
                          }
                          type="button"
                        >
                          Female
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <p className="font-light text-modalText text-sm mb-1 ">
                    Booking For
                  </p>
                  <div className="flex flex-row">
                    <input
                      className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full mr-2"
                      placeholder="Select Date"
                      type="date"
                      value={SelectedDate}
                      min={moment().format("YYYY-MM-DD")}
                      max={moment().add(2, "weeks").format("YYYY-MM-DD")}
                      onChange={(e) => {
                        const selectedDate = e.target.value;
                        const dayOfWeek = moment(selectedDate).day() + 1;
                        setIndex(dayOfWeek);
                        setSelectedDate(e.target.value);
                      }}
                    />
                    {options && options.length > 0 ? (
                      <select
                        value={session?.value}
                        className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                        onChange={(e) => {
                          setFormState((prevState) => ({
                            ...prevState,
                            allSlots: [""],
                            allTokens: [0],
                          }));
                          const label =
                            e.target.options[e.target.selectedIndex].text;
                          const value = e.target.value;
                          setSession({ label, value });
                          console.log(
                            "queue_type",
                            options?.filter(
                              (session) => session.value === value
                            )[0].data?.queue_type
                          );
                          setQueue_type(
                            String(
                              options?.filter(
                                (session) => session.value === value
                              )[0].data?.queue_type
                            )
                          );
                        }}
                      >
                        <option disabled hidden>
                          {String(session?.label).split(" - ")[0] +
                            " - " +
                            String(session?.label).split(" - ")[1]}
                        </option>
                        {options.map((session) => (
                          <option key={session.value} value={session.value}>
                            {String(session?.label).split(" - ")[0] +
                              " - " +
                              String(session?.label).split(" - ")[1]}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <NotFound text="No sessions" />
                    )}
                  </div>
                </div>
                {queue_type !== "Session" && (
                  <div className="flex flex-col w-full my-3">
                    <p className="font-light text-modalText text-sm mb-1">
                      {estimateVisitTime.includes(String(hospital_id))
                        ? "Estimate Visit Time"
                        : "Choose slot timings"}
                    </p>
                    {/* <div className="flex flex-row space-x-3 w-[292px] md:w-[490px] overflow-x-auto"> */}
                    <div>
                      {formState.currentIndex > 0 && (
                        <button
                          type="button"
                          className="bg-white p-2 rounded-lg hover:opacity-70"
                          onClick={showPrevious}
                        >
                          <LeftArrow />
                        </button>
                      )}
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 my-3">
                        {queue_type === "Token" ? (
                          formState.slicedTokens.length !== 0 &&
                          formState.slicedTokens[0] !== 0 ? (
                            formState.slicedTokens.map((item, index) => (
                              <React.Fragment key={index}>
                                <SelectToken
                                  selected={token === item}
                                  setToken={setToken}
                                  item={item}
                                />
                              </React.Fragment>
                            ))
                          ) : (
                            <NotFound text="No Tokens Available" />
                          )
                        ) : formState.slicedSlots.length !== 1 ||
                          formState.slicedSlots[0] !== "" ? (
                          formState.slicedSlots.map((item, index) => (
                            <React.Fragment key={index}>
                              <SelectSlot
                                item={item}
                                setSlot={setBookingSlot}
                                selected={bookingSlot?.includes(item) || false}
                                people={1}
                              />
                            </React.Fragment>
                          ))
                        ) : (
                          <NotFound text="No Slots Available" />
                        )}
                      </div>
                      <button
                        type="button"
                        className="bg-white p-2 rounded-lg hover:opacity-70"
                        onClick={showNext}
                      >
                        <RightArrow />
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {/* Footer */}
        <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
          {next && (
            <WhiteButton
              name="Back"
              handleClick={() => {
                setNext(false);
                setPatient((prevState) => ({
                  ...prevState,
                  full_name: "",
                  contact_number: "",
                  patient_id: "",
                  registered: false,
                }));
              }}
            />
          )}
          {options?.length !== 0 && next && (
            <DarkBlueButton
              type="submit"
              name="Save"
              handleClick={handleSubmit}
            />
          )}
          <div className="flex justify-end mr-4">
            {!next && (
              <DarkBlueButton
                type="submit"
                name="Next"
                handleClick={handleSubmit}
              />
            )}
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddBookingModal;
