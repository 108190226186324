import React from "react";
import moment from "moment";

const SelectSlot = ({
  selected,
  setSlot,
  item,
  people,
}: {
  selected: boolean;
  setSlot: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  item: string;
  people: number;
}) => {
  const handleTokenClick = () => {
    setSlot((prevTokens) => {
      // Initialize the array if it's undefined
      let updatedTokens = prevTokens ? [...prevTokens] : [];

      // Check if the current item is already in the array
      if (!updatedTokens.includes(item)) {
        // Add the current item
        updatedTokens.push(item);

        // Ensure the array size does not exceed 'people' or 5, whichever is smaller
        const maxTokens = Math.min(people, 5);
        if (updatedTokens.length > maxTokens) {
          // Remove items from the beginning of the array to maintain the size
          updatedTokens = updatedTokens.slice(-maxTokens);
        }
      }

      return updatedTokens;
    });
  };

  // Display logic remains unchanged, adjust as necessary for the new 'item' structure
  if (selected) {
    return (
      <button
        className="min-w-52 bg-editBG border-[1px] border-editBG text-white px-4 py-2 rounded-[20px] flex flex-col items-center"
        type="button"
      >
        <p>
          {moment(item.split(" - ")[0], "HH:mm:ss").format("hh:mmA") +
            " - " +
            moment(item.split(" - ")[1], "HH:mm:ss").format("hh:mmA")}
        </p>
      </button>
    );
  }

  return (
    <button
      className="min-w-52 border-[1px] border-editBG text-editBG px-4 py-2 rounded-[20px] flex flex-col items-center hover:bg-purple-400 hover:text-white"
      onClick={handleTokenClick}
      type="button"
    >
      <p>
        {moment(item.split(" - ")[0], "HH:mm:ss").format("hh:mmA") +
          " - " +
          moment(item.split(" - ")[1], "HH:mm:ss").format("hh:mmA")}
      </p>
    </button>
  );
};

export default SelectSlot;
