import React, { useEffect, useState } from "react";
import { DocProfileData } from "../../services/types";
import EditDocModal from "../organisms/modals/EditDocModal";
import { modalStyles } from "../../assets/styles/modalStyles";
import { useParams } from "react-router-dom";
import { momentIN } from "../../lib/utils/funcs";
import OverflowString from "../atoms/OverflowString";
import { getDoctorAvailability } from "../../lib/apis/doctor";

const BasicInfo = ({
  data,
  setData,
}: {
  data: DocProfileData | undefined;
  setData: React.Dispatch<React.SetStateAction<DocProfileData | undefined>>;
}) => {
  const [visible, setVisible] = useState(false);

  const param = useParams();
  const mapping_id = String(param.mapping_id);

  useEffect(() => {
    const fetchDocAvailability = async () => {
      const res = await getDoctorAvailability(mapping_id);
      if (res?.status === 200) {
        setData(res.data.result.doctor_details);
      }
    };
    fetchDocAvailability();
  }, [visible]);

  return (
    <div className="bg-white flex flex-col rounded-lg">
      <div className="flex flex-row justify-between items-center rounded-t-lg border-[1px] border-b-doctorsBorder px-5 py-4">
        <p className="text-dark font-medium">Basic Info</p>
        <button
          className="bg-editBG text-white px-5 py-[6px] rounded-[20px]"
          onClick={() => setVisible(true)}
        >
          Edit
        </button>
      </div>
      <div className="rounded-b-lg p-4 border-[1px] border-t-0 border-doctorsBorder space-y-6">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-6 md:col-span-4 flex flex-col">
            <p className="text-docDetail text-[10px] md:text-xs mb-[6px]">
              Full Name
            </p>
            <p className="text-dark text-sm">{data?.full_name}</p>
          </div>
          <div className="col-span-6 md:col-span-4 flex flex-col">
            <p className="text-docDetail text-[10px] md:text-xs mb-[6px]">
              DOB
            </p>
            <p className="text-dark text-sm">
              {momentIN(data?.date_of_birth).format("DD/MM/YYYY")}
            </p>
          </div>
          <div className="col-span-6 md:col-span-4 flex flex-col">
            <p className="text-docDetail text-[10px] md:text-xs mb-[6px]">
              Degree
            </p>
            <OverflowString
              text={data?.qualification}
              length={10}
              className="!text-dark !text-sm"
            />
          </div>
          <div className="col-span-6 md:col-span-4 flex flex-col">
            <p className="text-docDetail text-[10px] md:text-xs mb-[6px]">
              Specialization
            </p>
            <p className="text-dark text-sm">{data?.specialization}</p>
          </div>
          <div className="col-span-6 md:col-span-4 flex flex-col">
            <p className="text-docDetail text-[10px] md:text-xs mb-[6px]">
              Phone Number
            </p>
            <p className="text-dark text-sm">{data?.contact_number}</p>
          </div>
          <div className="col-span-6 md:col-span-4 flex flex-col">
            <p className="text-docDetail text-[10px] md:text-xs mb-[6px]">
              Email Address
            </p>
            <OverflowString
              className="!text-dark !text-sm !mt-0"
              text={data?.email}
              length={10}
            />
          </div>
        </div>
      </div>
      <EditDocModal
        modalIsOpen={visible}
        closeModal={() => setVisible(false)}
        customStyles={modalStyles}
        data={data}
      />
    </div>
  );
};

export default BasicInfo;
