export const URLS = {
  doctor: "https://api.a.doctrue.in/api/v1/doctor",

  user: "https://api.a.doctrue.in/api/v1/user",

  hosptialLogin: "https://api.a.doctrue.in/api/v1/hospital/auth/login",

  hospital: "https://api.a.doctrue.in/api/v1/hospital",

  hospitalDoctorMap:
    "https://api.a.doctrue.in/api/v1/hospital/doctor/availability",

  getDoctorList: "https://api.a.doctrue.in/api/v1/hospital",

  booking: "https://api.a.doctrue.in/api/v1/booking",

  getQueue: "https://api.a.doctrue.in/api/v1/queue",

  checkIn: "https://api.a.doctrue.in/api/v1/queue/check-in",

  queueStatus: "https://api.a.doctrue.in/api/v1/queue/status",

  getPatientsMapping: "https://api.a.doctrue.in/api/v1/patient/doctor",

  getPatientsHospital: "https://api.a.doctrue.in/api/v1/patient/hospital",

  refreshToken: "https://api.a.doctrue.in/api/v1/hospital/auth/refresh",

  changePW: "https://api.a.doctrue.in/api/v1/hospital/auth/changePassword",

  tokens: "https://api.a.doctrue.in/api/v1/hospital/availability/tokens",

  slots: "https://api.a.doctrue.in/api/v1/hospital/availability/slots",

  setDelay: "https://api.a.doctrue.in/api/v1/hospital/delay",

  getDelay: "https://api.a.doctrue.in/api/v1/hospital/get_delay",

  setCheckIn: "https://api.a.doctrue.in/api/v1/hospital/doc_delay",

  getCheckIn: "https://api.a.doctrue.in/api/v1/hospital/get_doc_delay",

  updateAvail:
    "https://api.a.doctrue.in/api/v1/hospital/doctor/availability/bulk",
};
