import React from "react";
import "../../assets/styles/table.css";
import Token from "../atoms/Token";
import ViewDetailsButton from "../atoms/buttons/ViewDetailsButton";
import moment from "moment";
import ProfilePicture from "../atoms/ProfilePicture";
import ViewQueueButton from "../atoms/buttons/ViewQueueButton";
import { toast } from "react-toastify";
import { momentIN } from "../../lib/utils/funcs";
import OverflowString from "../atoms/OverflowString";
import { useHospDocData } from "../../lib/contexts/HospitalDoctorContext";
import { Booking, Doctor, Patient } from "../../lib/utils/types";
import { usePatientBooking } from "../../lib/contexts/PatientBookingContext";
import {
  getBookingListByAvailabilityId,
  getBookingListByHospitalId,
  updateBookingStatus,
} from "../../lib/apis/booking";

type TableProps = {
  headers: string[];
  type: string;
  docData?: Doctor[];
  patientData?: Patient[];
  bookings?: Booking[];
  availability_id?: string;
  queue_type?: string | undefined;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setBookingID?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPatient?: React.Dispatch<React.SetStateAction<Patient | undefined>>;
};

const Table: React.FC<TableProps> = ({
  headers,
  type,
  docData,
  patientData,
  bookings,
  availability_id,
  queue_type,
  setVisible,
  setBookingID,
  setPatient,
}) => {
  const { hospitalID } = useHospDocData();
  const { setBookings, setAppointmentsData, SelectedDate } =
    usePatientBooking();

  const renderHeader = () => {
    let headerElement = headers;

    return headerElement
      .filter((item) => item !== "")
      .map((key, index) => {
        return (
          <th key={index} className="md:text-[10px] lg:text-sm">
            {key}
          </th>
        );
      });
  };

  const DocListBody = () => {
    return (
      docData &&
      docData
        .slice(0, 2)
        .map(
          (
            {
              doctor_id,
              mapping_id,
              profile_picture,
              full_name,
              gender,
              date_of_birth,
              contact_number,
              password,
              email,
              specialization,
              experience_years,
              qualification,
              created_by_user_id,
            },
            id
          ) => {
            return (
              <tr
                key={id}
                className={id % 2 === 0 ? "bg-white" : "bg-tableStrip"}
              >
                <td>
                  <div className="flex flex-row items-center w-20 md:w-56 lg:w-56">
                    {profile_picture === full_name ? (
                      <ProfilePicture
                        username={full_name}
                        className="hidden md:flex"
                      />
                    ) : (
                      <img
                        className={`w-10 h-10 object-contain rounded-full hidden md:flex`}
                        src={profile_picture}
                        alt="doctor"
                      ></img>
                    )}
                    <div className="flex flex-col ml-2">
                      <p className="text-sm md:text-xs lg:text-base">
                        {full_name}
                        {/* svdsdv sdsvsd */}
                      </p>
                      <p className="text-docDetail hidden md:flex md:flex-col md:text-[8px] lg:text-xs mt-1">
                        <OverflowString
                          text={qualification + ", " + specialization + ", "}
                          length={20}
                        />
                        {moment().diff(moment(date_of_birth), "years")} Years{" "}
                        {gender}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p className="text-sm lg:text-base">
                    {contact_number?.substring(2)}
                  </p>
                  <OverflowString text={email} length={10} />
                </td>
                <td>
                  <ViewQueueButton
                    mapping_id={mapping_id}
                    textStyle="!text-[10px] md:!text-xs lg:!text-sm"
                  />
                </td>
              </tr>
            );
          }
        )
    );
  };

  const DoctorsBody = () => {
    return (
      docData &&
      docData.map(
        (
          {
            doctor_id,
            mapping_id,
            profile_picture,
            full_name,
            gender,
            date_of_birth,
            contact_number,
            password,
            email,
            specialization,
            experience_years,
            qualification,
            created_by_user_id,
          },
          id
        ) => {
          return (
            <tr
              key={id}
              className={id % 2 === 0 ? "bg-white" : "bg-tableStrip"}
            >
              <td
                className={`md:w-52 lg:w-64 sticky left-0 ${
                  id % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="flex flex-row items-center md:w-52 lg:w-64">
                  <div className="hidden md:flex">
                    {profile_picture === full_name ? (
                      <ProfilePicture username={full_name} />
                    ) : (
                      <img
                        className={`w-12 h-12 object-contain rounded-full`}
                        src={profile_picture}
                        alt="doctor"
                      ></img>
                    )}
                  </div>
                  <div className="flex flex-col ml-2">
                    <p className="text-sm lg:text-base">{full_name}</p>
                    <p className="text-docDetail md:text-[10px] lg:text-xs mt-1 hidden md:flex">
                      {gender}, {moment().diff(moment(date_of_birth), "years")}{" "}
                      Years{" "}
                    </p>
                  </div>
                </div>
              </td>
              <td className="md:w-40 lg:w-64">
                <div className="flex flex-col md:w-40 lg:w-64">
                  <p className="text-sm lg:text-base">{specialization}</p>
                  <OverflowString
                    hideForMobile
                    text={qualification}
                    length={20}
                  />
                </div>
              </td>
              <td className="md:w-32 lg:w-64">
                <div className="flex flex-col md:w-32 lg:w-64">
                  <p className="text-sm lg:text-base">
                    {contact_number?.substring(2)}
                  </p>
                  <OverflowString text={email} length={10} />
                </div>
              </td>
              <td
                className={`sticky right-0 ${
                  id % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="flex lg:flex-row space-x-4">
                  <ViewQueueButton
                    mapping_id={mapping_id}
                    textStyle="!text-[10px] lg:!text-sm"
                  />
                  <ViewDetailsButton
                    mapping_id={mapping_id}
                    textStyle="!text-[10px] lg:!text-sm"
                  />
                </div>
              </td>
            </tr>
          );
        }
      )
    );
  };

  const DashboardAppointmentsBody = () => {
    return (
      bookings &&
      bookings.map(
        (
          {
            booking_id,
            token_number,
            reference_id,
            status,
            full_name,
            patient_full_name,
            gender,
            patient_gender,
            date_of_birth,
            contact_number,
            patient_contact_number,
            email,
            patient_email,
            booked_slot_time,
            comments,
            booked_date,
            booking_time,
            booked_slot_start,
            booked_slot_end,
            doctor_full_name,
            doctor_profile_picture,
          },
          index
        ) => {
          return (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-white" : "bg-tableStrip"}
            >
              <td
                className={`sticky left-0 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="flex flex-row items-center">
                  <ProfilePicture
                    username={patient_full_name}
                    className="md:w-8 md:h-8 lg:w-10 lg:h-10 hidden md:flex"
                  />
                  <div className="flex flex-col ml-2 text-left">
                    <p className="text-dark text-sm md:text-base">
                      {patient_full_name}
                    </p>
                    {patient_gender !== " " && (
                      <p className="text-docDetail text-xs mt-1 hidden md:flex">
                        {patient_gender}
                        {/* , {moment().diff(moment(date_of_birth), "years")}{" "}Years */}
                      </p>
                    )}
                  </div>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center">
                  {doctor_profile_picture === doctor_full_name ? (
                    <ProfilePicture
                      username={doctor_full_name}
                      className="hidden lg:flex"
                    />
                  ) : (
                    <img
                      className={`w-10 h-10 object-contain rounded-full hidden lg:flex`}
                      src={doctor_profile_picture}
                      alt="doctor"
                    ></img>
                  )}
                  <p className="text-dark text-sm md:text-base ml-0 md:ml-2">
                    {doctor_full_name}
                  </p>
                </div>
              </td>
              <td>
                <div className="text-left">
                  <p className="text-dark text-sm md:text-base">
                    {patient_contact_number.substring(2)}
                  </p>
                  <OverflowString text={patient_email} length={10} />
                </div>
              </td>
            </tr>
          );
        }
      )
    );
  };

  const AppointmentsBody = () => {
    return (
      bookings &&
      bookings.map(
        (
          {
            booking_id,
            token_number,
            reference_id,
            status,
            full_name,
            patient_full_name,
            gender,
            patient_gender,
            date_of_birth,
            contact_number,
            patient_contact_number,
            email,
            patient_email,
            booked_slot_time,
            comments,
            booked_date,
            booking_time,
            booked_slot_start,
            booked_slot_end,
            doctor_full_name,
            doctor_profile_picture,
          },
          index
        ) => {
          return (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-white" : "bg-tableStrip"}
            >
              <td
                className={`sticky left-0 w-20 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <Token refno onGoing={status === 2} value={reference_id} />
              </td>
              <td
                className={`sticky left-20 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="flex flex-col ml-2 text-left">
                  <p className="text-dark text-sm md:text-base">
                    {patient_full_name}
                  </p>
                  {patient_gender !== " " && (
                    <p className="text-docDetail text-xs mt-1 hidden md:flex">
                      {patient_gender}
                      {/* , {moment().diff(moment(date_of_birth), "years")}{" "}Years */}
                    </p>
                  )}
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center">
                  {doctor_profile_picture === doctor_full_name ? (
                    <ProfilePicture
                      username={doctor_full_name}
                      className="hidden lg:flex"
                    />
                  ) : (
                    <img
                      className={`w-10 h-10 object-contain rounded-full hidden lg:flex`}
                      src={doctor_profile_picture}
                      alt="doctor"
                    ></img>
                  )}
                  <p className="text-dark text-sm md:text-base ml-0 md:ml-2">
                    {doctor_full_name}
                  </p>
                </div>
              </td>
              <td>
                <div className="text-left">
                  <p className="text-dark text-sm md:text-base">
                    {patient_contact_number.substring(2)}
                  </p>{" "}
                  <OverflowString text={patient_email} length={10} />
                </div>
              </td>
              <td
                className={`sticky right-0 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="flex flex-row space-x-4">
                  <button
                    className="hidden md:flex bg-nextPatient text-white text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-blue-400 truncate"
                    onClick={async () => {
                      const res = await updateBookingStatus({
                        bookingId: booking_id,
                        status: 1,
                      });
                      if (res?.status === 200) {
                        const booked_data = await getBookingListByHospitalId(
                          hospitalID
                        );
                        if (booked_data?.status === 200) {
                          setAppointmentsData(booked_data.data.result);
                          console.log(booked_data.data.result);
                        } else if (booked_data?.status === 404) {
                          setAppointmentsData(undefined);
                        }
                      }
                    }}
                  >
                    Check-In
                  </button>
                  <button
                    className="border-[1px] border-onLeave text-onLeave text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-red-400 hover:text-white"
                    onClick={async () => {
                      const res = await updateBookingStatus({
                        bookingId: booking_id,
                        status: 4,
                      });
                      if (res?.status === 200) {
                        const booked_data = await getBookingListByHospitalId(
                          hospitalID
                        );
                        if (booked_data?.status === 200) {
                          setAppointmentsData(booked_data.data.result);
                          console.log(booked_data.data.result);
                        } else if (booked_data?.status === 404) {
                          setAppointmentsData(undefined);
                        }
                      }
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="hidden md:flex border-[1px] border-nextPatient text-nextPatient text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-nextPatient hover:text-white"
                    onClick={() => {
                      window.location.href = `tel:${patient_contact_number}`;
                    }}
                  >
                    Call
                  </button>
                </div>
              </td>
            </tr>
          );
        }
      )
    );
  };

  const PatientsBody = () => {
    return (
      patientData &&
      patientData
        .sort((a, b) => {
          if (moment(a.booked_date).isBefore(b.booked_date)) {
            return 1; // a is before b
          } else if (moment(b.booked_date).isBefore(a.booked_date)) {
            return -1; // b is before a
          } else {
            return 0; // a and b are equal
          }
        })
        .map((patient, index) => {
          return (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-white" : "bg-tableStrip"}
            >
              <td
                className={`sticky left-0 w-20 md:w-48 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="flex flex-row">
                  <ProfilePicture
                    username={patient.full_name}
                    className="md:w-8 md:h-8 lg:w-10 lg:h-10 hidden md:flex"
                  />
                  <div className="flex flex-col ml-2 text-left">
                    <p className="text-dark text-sm md:text-xs lg:text-base">
                      {patient.full_name}
                    </p>
                    {patient.gender !== " " && (
                      <p className="text-docDetail text-xs md:text-[8px] lg:text-xs md:mt-1">
                        {patient.gender}
                        {/*,{" "}
                         {moment().diff(moment(date_of_birth), "years")} Years */}
                      </p>
                    )}
                  </div>
                </div>
              </td>
              <td
                className={`sticky left-20 md:left-48 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="text-left">
                  <p className="text-dark text-sm md:text-xs lg:text-base">
                    {patient.contact_number.substring(2)}
                  </p>
                  <OverflowString text={patient.email} length={10} />
                </div>
              </td>
              <td>
                <div className="text-left">
                  <p className="text-dark text-sm md:text-xs lg:text-base truncate">
                    {moment(patient.booked_date).format("DD MMM YYYY")}
                  </p>
                  {/* <p className="text-docDetail text-xs md:text-[8px] lg:text-xs mt-1 ">
                      {moment(booked_slot_time, "HH:mm:ss").format("h:mmA")}
                    </p> */}
                </div>
              </td>
              <td
                className={`sticky right-0 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="flex flex-row space-x-4">
                  <button
                    className="bg-nextPatient text-white px-4 lg:px-6 py-2 rounded hover:bg-blue-400"
                    onClick={() => {
                      setPatient && setPatient(patient);
                      setVisible && setVisible(true);
                    }}
                  >
                    <p className="text-sm md:text-xs lg:text-base">Update</p>
                  </button>
                  <button
                    className="border-[1px] border-nextPatient text-nextPatient px-4 lg:px-6 py-2 rounded hover:bg-nextPatient hover:text-white"
                    onClick={() => {
                      window.location.href = `tel:${patient.contact_number}`;
                    }}
                  >
                    <p className="text-sm md:text-xs lg:text-base">Call</p>
                  </button>
                </div>
              </td>
            </tr>
          );
        })
    );
  };

  const InClinicBody = () => {
    return (
      bookings &&
      bookings
        .sort((a, b) => b.status - a.status)
        .map(
          (
            {
              booking_id,
              token_number,
              full_name,
              gender,
              date_of_birth,
              contact_number,
              email,
              check_in_time,
              comments,
              status,
              booked_slot_start,
              booked_slot_end,
              booking_mode,
            },
            index
          ) => {
            return (
              <tr
                key={index}
                className={
                  index % 2 === 0 ? "bg-white text-sm" : "bg-tableStrip text-sm"
                }
              >
                {queue_type !== "Session" && (
                  <td
                    className={`sticky left-0 w-20 ${
                      index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                    }`}
                  >
                    {queue_type === "Token" || queue_type === "Slot_Token" ? (
                      <Token onGoing={status === 2} value={token_number} />
                    ) : (
                      <p className="text-dark truncate">
                        {moment(booked_slot_start, "HH:mm:ss").format("hh:mmA")}
                        {" - "}
                        {booked_slot_end === null
                          ? moment(booked_slot_start, "HH:mm:ss").format(
                              "hh:mmA"
                            )
                          : moment(booked_slot_end, "HH:mm:ss").format(
                              "hh:mmA"
                            )}
                      </p>
                    )}
                  </td>
                )}
                <td
                  className={`sticky ${
                    queue_type !== "Session" ? "left-20" : "left-0"
                  } ${index % 2 === 0 ? "bg-white" : "bg-tableStrip"}`}
                >
                  <div className="flex flex-col ml-2 text-left">
                    <p className="text-dark">{full_name}</p>
                    {gender !== " " && (
                      <p className="text-docDetail text-xs mt-1 hidden md:flex">
                        {gender}
                        {/* , {moment().diff(moment(date_of_birth), "years")}{" "} 
                      Years*/}
                      </p>
                    )}
                  </div>
                </td>
                <td>
                  <div className="text-left">
                    <p className="text-dark">{contact_number.substring(2)}</p>
                    <OverflowString text={email} length={10} />
                  </div>
                </td>
                <td>
                  <div className="text-left">
                    <p className="text-dark truncate">
                      {momentIN(check_in_time).format("DD MMM YYYY, hh:mmA")}
                    </p>
                    <p className="text-docDetail text-xs mt-1  w-40">
                      {comments !== "test" && comments !== "" && (
                        <OverflowString text={comments} length={20} />
                      )}
                    </p>
                  </div>
                </td>
                <td
                  className={`sticky right-0 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  {status === 0 || status === 1 ? (
                    <div className="flex flex-row items-center space-x-4">
                      <button
                        className="flex self-start rounded-[4px] bg-nextPatient p-3 md:px-5 lg:px-7 md:py-3 text-xs md:text-sm text-white hover:bg-queueHover truncate"
                        onClick={async () => {
                          const onGoingPatient = bookings.filter(
                            (booking) => booking.status === 2
                          );
                          if (onGoingPatient.length !== 0) {
                            const onGoingID = onGoingPatient[0].booking_id;
                            console.log(onGoingID);
                            await updateBookingStatus({
                              bookingId: onGoingID,
                              status: 3,
                            });
                          }
                          const sendPatientOnGoing = await updateBookingStatus({
                            bookingId: booking_id,
                            status: 2,
                          });
                          if (sendPatientOnGoing?.status === 200) {
                            const booked_data =
                              await getBookingListByAvailabilityId(
                                availability_id,
                                SelectedDate
                              );
                            if (booked_data?.status === 200)
                              setBookings(booked_data.data.result);
                          } else if (sendPatientOnGoing?.status === 404) {
                            toast.error(sendPatientOnGoing.data.error);
                          }
                        }}
                      >
                        Next Patient
                      </button>
                      {booking_mode === "walk-in" ? (
                        <button
                          disabled
                          className="hidden md:flex border-[1px] border-available text-available text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-available hover:text-white"
                        >
                          Walk-In
                        </button>
                      ) : (
                        <button
                          className="hidden md:flex border-[1px] border-onLeave text-onLeave text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-onLeave hover:text-white"
                          onClick={async () => {
                            const res = await updateBookingStatus({
                              bookingId: booking_id,
                              status: 0,
                            });
                            if (res?.status === 200) {
                              const booked_data =
                                await getBookingListByAvailabilityId(
                                  availability_id,
                                  SelectedDate
                                );
                              if (booked_data?.status === 200) {
                                setBookings(booked_data.data.result);
                                console.log(booked_data.data.result);
                              }
                            }
                          }}
                        >
                          Check Out
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="flex flex-row space-x-4">
                      <button
                        className="bg-available text-sm text-white px-4 py-3 rounded truncate"
                        disabled
                      >
                        On-Going
                      </button>
                      <button
                        className=" border-[1px] border-available text-available px-4 py-3 rounded hover:bg-green-400 hover:text-white hidden md:flex"
                        onClick={async () => {
                          const res = await updateBookingStatus({
                            bookingId: booking_id,
                            status: 3,
                          });
                          if (res?.status === 200) {
                            const booked_data =
                              await getBookingListByAvailabilityId(
                                availability_id,
                                SelectedDate
                              );
                            if (booked_data?.status === 200)
                              setBookings(booked_data.data.result);
                          }
                        }}
                      >
                        Complete
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            );
          }
        )
    );
  };

  const BookedBody = () => {
    return (
      bookings &&
      bookings
        .sort((a, b) => a.token_number - b.token_number)
        .map(
          (
            {
              booking_id,
              token_number,
              reference_id,
              status,
              full_name,
              gender,
              date_of_birth,
              contact_number,
              email,
              booked_slot_time,
              comments,
              booked_date,
              booking_time,
              booked_slot_start,
              booked_slot_end,
              booking_mode,
            },
            index
          ) => {
            return (
              <tr
                key={index}
                className={
                  index % 2 === 0 ? "bg-white text-sm" : "bg-tableStrip text-sm"
                }
              >
                <td
                  className={`sticky left-0 w-20 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <Token
                    refno={queue_type !== "Token"}
                    onGoing={status === 2}
                    value={queue_type === "Token" ? token_number : reference_id}
                  />
                </td>
                <td
                  className={`sticky left-20 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <div className="flex flex-col ml-2 text-left">
                    <p className="text-dark">{full_name}</p>
                    {gender !== " " && (
                      <p className="text-docDetail text-xs mt-1 hidden md:flex">
                        {gender}
                        {/* , {moment().diff(moment(date_of_birth), "years")}{" "}Years */}
                      </p>
                    )}
                  </div>
                </td>
                <td>
                  <div className="text-left">
                    <p className="text-dark">{contact_number.substring(2)}</p>
                    <OverflowString text={email} length={10} />
                  </div>
                </td>
                <td>
                  {queue_type === "Token" || queue_type === "Session" ? (
                    <div className="text-left">
                      <p className="text-dark truncate">
                        {momentIN(booking_time).format("DD MMM YYYY, hh:mmA")}
                      </p>
                      <p className="text-docDetail text-xs mt-1 w-40 ">
                        {comments !== "test" && comments !== "" && (
                          <OverflowString text={comments} length={20} />
                        )}
                      </p>
                    </div>
                  ) : (
                    <div className="text-left">
                      <p className="text-dark truncate">
                        {moment(booked_slot_start, "HH:mm:ss").format("hh:mmA")}
                        {" - "}
                        {booked_slot_end === null
                          ? moment(booked_slot_start, "HH:mm:ss").format(
                              "hh:mmA"
                            )
                          : moment(booked_slot_end, "HH:mm:ss").format(
                              "hh:mmA"
                            )}
                      </p>
                      {comments !== "test" && comments !== "" && (
                        <OverflowString text={comments} length={20} />
                      )}
                    </div>
                  )}
                </td>
                <td
                  className={`sticky right-0 md:left-0 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  {SelectedDate === moment().format("YYYY-MM-DD") ? (
                    <div className="flex flex-row space-x-4">
                      <button
                        className="bg-nextPatient text-white text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-blue-400 truncate"
                        onClick={async () => {
                          const res = await updateBookingStatus({
                            bookingId: booking_id,
                            status: 1,
                          });
                          if (res?.status === 200) {
                            const booked_data =
                              await getBookingListByAvailabilityId(
                                availability_id,
                                SelectedDate
                              );
                            if (booked_data?.status === 200) {
                              setBookings(booked_data.data.result);
                              console.log(booked_data.data.result);
                            }
                          }
                        }}
                      >
                        Check-In
                      </button>
                      <button
                        className="hidden md:flex border-[1px] border-available text-available text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-available hover:text-white"
                        onClick={() => {
                          setVisible && setVisible(true);
                          setBookingID && setBookingID(booking_id);
                        }}
                      >
                        <p className="text-sm truncate">Reschedule</p>
                      </button>
                      <button
                        className="hidden md:flex border-[1px] border-onLeave text-onLeave text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-onLeave hover:text-white"
                        onClick={async () => {
                          const res = await updateBookingStatus({
                            bookingId: booking_id,
                            status: 4,
                          });
                          if (res?.status === 200) {
                            const booked_data =
                              await getBookingListByAvailabilityId(
                                availability_id,
                                SelectedDate
                              );
                            if (booked_data?.status === 200) {
                              setBookings(booked_data.data.result);
                              console.log(booked_data.data.result);
                            }
                          }
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-row space-x-4">
                      <button
                        className="hidden md:flex border-[1px] border-available text-available text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-available hover:text-white"
                        onClick={() => {
                          setVisible && setVisible(true);
                          setBookingID && setBookingID(booking_id);
                        }}
                      >
                        <p className="text-sm truncate">Reschedule</p>
                      </button>
                      <button
                        className="flex bg-onLeave text-white px-4 py-3 rounded hover:bg-red-400"
                        onClick={async () => {
                          const res = await updateBookingStatus({
                            bookingId: booking_id,
                            status: 4,
                          });
                          if (res?.status === 200) {
                            const booked_data =
                              await getBookingListByAvailabilityId(
                                availability_id,
                                SelectedDate
                              );
                            if (booked_data?.status === 200) {
                              setBookings(booked_data.data.result);
                              console.log(booked_data.data.result);
                            }
                          }
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            );
          }
        )
    );
  };

  const FollowupBody = () => {
    return (
      bookings &&
      bookings
        .sort((a, b) => a.token_number - b.token_number)
        .map(
          (
            {
              booking_id,
              token_number,
              reference_id,
              status,
              full_name,
              gender,
              date_of_birth,
              contact_number,
              patient_contact_number,
              email,
              booked_slot_time,
              comments,
              booked_date,
              booking_time,
              booked_slot_start,
              booked_slot_end,
              booking_mode,
            },
            index
          ) => {
            return (
              <tr
                key={index}
                className={
                  index % 2 === 0 ? "bg-white text-sm" : "bg-tableStrip text-sm"
                }
              >
                <td
                  className={`sticky left-0 w-20 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <Token refno onGoing={status === 2} value={reference_id} />
                </td>
                <td
                  className={`sticky left-20 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <div className="flex flex-col ml-2 text-left">
                    <p className="text-dark">{full_name}</p>
                    {gender !== " " && (
                      <p className="text-docDetail text-xs mt-1 hidden md:flex">
                        {gender}
                        {/* , {moment().diff(moment(date_of_birth), "years")}{" "}Years */}
                      </p>
                    )}
                  </div>
                </td>
                <td>
                  <div className="text-left">
                    <p className="text-dark">{contact_number.substring(2)}</p>
                    <OverflowString text={email} length={10} />
                  </div>
                </td>
                <td>
                  {queue_type === "Token" || queue_type === "Session" ? (
                    <div className="text-left">
                      <p className="text-dark truncate">
                        {momentIN(booking_time).format("DD MMM YYYY, hh:mmA")}
                      </p>
                      <p className="text-docDetail text-xs mt-1 w-40 ">
                        {comments !== "test" && comments !== "" && (
                          <OverflowString text={comments} length={20} />
                        )}
                      </p>
                    </div>
                  ) : (
                    <div className="text-left">
                      <p className="text-dark truncate">
                        {moment(booked_slot_start, "HH:mm:ss").format("hh:mmA")}
                        {" - "}
                        {booked_slot_end === null
                          ? moment(booked_slot_start, "HH:mm:ss").format(
                              "hh:mmA"
                            )
                          : moment(booked_slot_end, "HH:mm:ss").format(
                              "hh:mmA"
                            )}
                      </p>
                      {comments !== "test" && comments !== "" && (
                        <OverflowString text={comments} length={20} />
                      )}
                    </div>
                  )}
                </td>
                <td
                  className={`sticky right-0 md:left-0 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  {SelectedDate === moment().format("YYYY-MM-DD") ? (
                    <div className="flex flex-row space-x-4">
                      <button
                        className="bg-nextPatient text-white text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-blue-400 truncate"
                        onClick={async () => {
                          const res = await updateBookingStatus({
                            bookingId: booking_id,
                            status: 1,
                          });
                          if (res?.status === 200) {
                            const booked_data =
                              await getBookingListByAvailabilityId(
                                availability_id,
                                SelectedDate
                              );
                            if (booked_data?.status === 200) {
                              setBookings(booked_data.data.result);
                              console.log(booked_data.data.result);
                            }
                          }
                        }}
                      >
                        Check-In
                      </button>
                      <button
                        className="hidden md:flex border-[1px] border-available text-available text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-available hover:text-white"
                        onClick={() => {
                          setVisible && setVisible(true);
                          setBookingID && setBookingID(booking_id);
                        }}
                      >
                        <p className="text-sm truncate">Reschedule</p>
                      </button>
                      <button
                        className="hidden md:flex border-[1px] border-onLeave text-onLeave text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-onLeave hover:text-white"
                        onClick={async () => {
                          const res = await updateBookingStatus({
                            bookingId: booking_id,
                            status: 4,
                          });
                          if (res?.status === 200) {
                            const booked_data =
                              await getBookingListByAvailabilityId(
                                availability_id,
                                SelectedDate
                              );
                            if (booked_data?.status === 200) {
                              setBookings(booked_data.data.result);
                              console.log(booked_data.data.result);
                            }
                          }
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="hidden md:flex border-[1px] border-nextPatient text-nextPatient text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-nextPatient hover:text-white"
                        onClick={() => {
                          window.location.href = `tel:${contact_number}`;
                        }}
                      >
                        Call
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-row space-x-4">
                      <button
                        className="hidden md:flex border-[1px] border-nextPatient text-nextPatient text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-nextPatient hover:text-white"
                        onClick={() => {
                          window.location.href = `tel:${contact_number}`;
                        }}
                      >
                        Call
                      </button>
                      <button
                        className="hidden md:flex border-[1px] border-available text-available text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-available hover:text-white"
                        onClick={() => {
                          setVisible && setVisible(true);
                          setBookingID && setBookingID(booking_id);
                        }}
                      >
                        <p className="text-sm truncate">Reschedule</p>
                      </button>
                      <button
                        className="flex bg-onLeave text-white px-4 py-3 rounded hover:bg-red-400"
                        onClick={async () => {
                          const res = await updateBookingStatus({
                            bookingId: booking_id,
                            status: 4,
                          });
                          if (res?.status === 200) {
                            const booked_data =
                              await getBookingListByAvailabilityId(
                                availability_id,
                                SelectedDate
                              );
                            if (booked_data?.status === 200) {
                              setBookings(booked_data.data.result);
                              console.log(booked_data.data.result);
                            }
                          }
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            );
          }
        )
    );
  };

  const CompletedBody = () => {
    return (
      bookings &&
      bookings
        .filter((item) => item.status === 3)
        .map(
          (
            {
              booking_id,
              token_number,
              full_name,
              gender,
              date_of_birth,
              contact_number,
              email,
              check_in_time,
              comments,
              booked_date,
              booking_time,
              last_updated_at,
              reference_id,
              has_follow_up,
            },
            index
          ) => {
            return (
              <tr
                key={index}
                className={
                  index % 2 === 0 ? "bg-white text-sm" : "bg-tableStrip text-sm"
                }
              >
                <td
                  className={`sticky left-0 w-20 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <div className="flex flex-row items-center w-20 md:w-36 lg:w-56">
                    <ProfilePicture
                      username={full_name}
                      className="hidden md:flex"
                    />
                    <div className="flex flex-col ml-2 text-left">
                      <p className="">{full_name}</p>
                      {gender !== " " && (
                        <p className="text-docDetail text-xs mt-1 ">
                          {gender}
                          {/* ,{" "}{moment().diff(moment(date_of_birth), "years")} Years */}
                        </p>
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="text-left">
                    <p className="">{contact_number.substring(2)}</p>
                    <OverflowString text={email} length={10} />
                  </div>
                </td>
                <td>
                  <div className="text-left">
                    <p className="">
                      {momentIN(last_updated_at).format("DD MMM YYYY, hh:mmA")}
                    </p>
                    <p className="text-docDetail text-xs mt-1  w-40">
                      {comments !== "test" && comments !== "" && (
                        <OverflowString text={comments} length={20} />
                      )}
                    </p>
                  </div>
                </td>
                <td
                  className={`sticky right-0 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  {has_follow_up ? (
                    <button
                      className="flex self-start px-3 py-2 rounded bg-available"
                      disabled
                    >
                      <p className="text-sm text-white truncate">
                        Follow Up Booked
                      </p>
                    </button>
                  ) : (
                    <button
                      className={`flex self-start border-[1px] border-available text-available hover:text-white px-3 py-2 rounded hover:bg-available`}
                      onClick={() => {
                        setVisible && setVisible(true);
                        setBookingID && setBookingID(booking_id);
                      }}
                    >
                      <p className="text-sm truncate">Follow Up</p>
                    </button>
                  )}
                </td>
              </tr>
            );
          }
        )
    );
  };

  const CancelledBody = () => {
    return (
      bookings &&
      bookings
        .filter((item) => item.status === 4)
        .map(
          (
            {
              booking_id,
              token_number,
              full_name,
              gender,
              date_of_birth,
              contact_number,
              email,
              check_in_time,
              comments,
              booked_date,
              booking_time,
              last_updated_at,
              reference_id,
            },
            index
          ) => {
            return (
              <tr
                key={index}
                className={
                  index % 2 === 0 ? "bg-white text-sm" : "bg-tableStrip text-sm"
                }
              >
                <td
                  className={`sticky left-0 w-20 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <div className="flex flex-row items-center w-20 md:w-36 lg:w-56">
                    <ProfilePicture
                      username={full_name}
                      className="hidden md:flex"
                    />
                    <div className="flex flex-col ml-2 text-left">
                      <p className="">{full_name}</p>
                      {gender !== " " && (
                        <p className="text-docDetail text-xs mt-1 ">
                          {gender}
                          {/* ,{" "}{moment().diff(moment(date_of_birth), "years")} Years */}
                        </p>
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="text-left">
                    <p className="">{contact_number.substring(2)}</p>
                    <OverflowString text={email} length={10} />
                  </div>
                </td>
                <td>
                  <div className="text-left">
                    <p className="">
                      {" "}
                      {momentIN(booking_time).format("DD MMM YYYY, hh:mmA")}
                    </p>
                    <p className="text-docDetail text-xs mt-1 w-40">
                      {comments !== "test" && comments !== "" && (
                        <OverflowString text={comments} length={20} />
                      )}
                    </p>
                  </div>
                </td>
                <td
                  className={`sticky right-0 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <p className="text-left truncate">
                    {" "}
                    {momentIN(last_updated_at).format("DD MMM YYYY, hh:mmA")}
                  </p>
                </td>
              </tr>
            );
          }
        )
    );
  };

  return (
    <>
      <table width="100%">
        <thead className="border-b-[0.5px] border-doctorsBorder">
          <tr>{renderHeader()}</tr>
        </thead>
        <tbody>
          {type === "doclist"
            ? DocListBody()
            : type === "dashapps"
            ? DashboardAppointmentsBody()
            : type === "doctor"
            ? DoctorsBody()
            : type === "inclinic"
            ? InClinicBody()
            : type === "booked"
            ? BookedBody()
            : type === "followup"
            ? FollowupBody()
            : type === "completed"
            ? CompletedBody()
            : type === "patients"
            ? PatientsBody()
            : type === "appointments"
            ? AppointmentsBody()
            : CancelledBody()}
        </tbody>
        {/* <tfoot className="p-4 bg-white h-10 border-[0.5px] border-doctorsBorder"></tfoot> */}
      </table>
    </>
  );
};

export default Table;
