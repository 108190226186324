import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./screens/Dashboard";
import Doctors from "./screens/Doctors";
import EachDoctor from "./screens/EachDoctor";
import Appointments from "./screens/Appointments";
import Sidebar from "./components/organisms/Sidebar";
import HelpCenter from "./screens/HelpCenter";
import Settings from "./screens/Settings";
import Signin from "./screens/Signin";
import DocDetails from "./screens/DocDetails";
import Patients from "./screens/Patients";
import ChooseHosp from "./screens/ChooseHosp";

const AppRoutes = () => {
  const location = useLocation();

  return (
    <div className="flex flex-col lg:flex-row">
      {location.pathname !== "/" && location.pathname !== "/admin" && (
        <Sidebar />
      )}
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="admin" element={<ChooseHosp />} />
        <Route path=":hospital_id">
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="doctors" element={<Doctors />} />
          <Route path="appointments" element={<Appointments />} />
          <Route path="patients" element={<Patients />} />
          <Route path="doctors/doc/:mapping_id" element={<EachDoctor />} />
          <Route path="doctors/:mapping_id" element={<DocDetails />} />
          <Route path="settings" element={<Settings />} />
        </Route>
      </Routes>
    </div>
  );
};

export default AppRoutes;
