import React, { FormEvent, useEffect, useState } from "react";
import Modal from "react-modal";
import ProfilePicture from "../../atoms/ProfilePicture";
import { useHospDocData } from "../../../lib/contexts/HospitalDoctorContext";
import WhiteButton from "../../atoms/buttons/WhiteButton";
import CloseButton from "../../atoms/buttons/CloseButton";
import DarkBlueButton from "../../atoms/buttons/DarkBlueButton";
import HospDetails from "../../molecules/HospDetails";
import ReactDropdown from "react-dropdown";
import { generateTimeOptions } from "../../../lib/utils/funcs";
import {} from "../../../services/apis";
import { toast } from "react-toastify";
import {
  getHosptialDetails,
  updateHosptialDetails,
} from "../../../lib/apis/hospital";
import { useUserData } from "../../../lib/contexts/UserContext";

const HospModal = ({
  closeModal,
  modalIsOpen,
  customStyles,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
}) => {
  const { userData } = useUserData();
  const { hospitalID, hospData, setHospData } = useHospDocData();

  const [edit, setEdit] = useState(false);
  const [name, setName] = useState(hospData?.hospital_name);
  const [phno, setPhno] = useState(hospData?.hospital_contact_number);
  const [email, setEmail] = useState(userData?.email);
  const [address, setAddress] = useState(hospData?.hospital_address);
  const [start, setStart] = useState(hospData?.hospital_timing.split(" - ")[0]);
  const [end, setEnd] = useState(hospData?.hospital_timing.split(" - ")[1]);

  useEffect(() => {
    setName(hospData?.hospital_name);
    setPhno(hospData?.hospital_contact_number);
    setEmail(userData?.email);
    setAddress(hospData?.hospital_address);
    setStart(hospData?.hospital_timing.split(" - ")[0]);
    setEnd(hospData?.hospital_timing.split(" - ")[1]);
  }, [modalIsOpen, hospData]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (hospitalID) {
      const data = {
        hospital_id: hospitalID,
        hospital_name: name?.trim(),
        hospital_timing: start + " - " + end,
        hospital_address: address,
      };
      const res = await updateHosptialDetails(data);
      if (res?.status === 200) {
        closeModal();
        setEdit(false);
        toast.success("Updated successfully!");

        const hosp_data = await getHosptialDetails(hospitalID);
        if (hosp_data?.status === 200) {
          setHospData(hosp_data.data.result);
        }
      }
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      {/* Header */}
      <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
        <p className="text-dark">
          {edit
            ? `Edit ${
                hospData?.category.toLowerCase() === "clinic"
                  ? "Clinic"
                  : "Hospital"
              } Details`
            : `${
                hospData?.category.toLowerCase() === "clinic"
                  ? "Clinic"
                  : "Hospital"
              } Details`}
        </p>
        <CloseButton
          handleClick={() => {
            closeModal();
            setEdit(false);
          }}
        />
      </div>

      {/* Body */}
      <div className="p-0">
        <form onSubmit={handleSubmit} className="mb-0">
          <div className="px-6 py-5 bg-lightGrey max-h-96 overflow-y-auto">
            {edit ? (
              <div className="flex flex-col">
                {hospData?.logo === "test" ? (
                  <ProfilePicture
                    username={hospData.hospital_name}
                    className={"!w-20 !h-20 self-center"}
                  />
                ) : (
                  <img
                    className={`w-20 h-20 rounded-full self-center`}
                    src={hospData?.logo}
                    alt="hospital-logo"
                  ></img>
                )}
                <div className="mt-3">
                  <p className="font-light text-modalText text-sm mb-1">
                    Clinic Name
                  </p>
                  <input
                    className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                    placeholder="Enter clinic name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    maxLength={23}
                  />
                </div>
                <div className="mt-3">
                  <p className="font-light text-modalText text-sm mb-1">
                    Timings
                  </p>
                  <div className="flex flex-row items-center space-x-3">
                    <ReactDropdown
                      options={generateTimeOptions("morning")}
                      onChange={(selectedOption) =>
                        setStart(selectedOption.value)
                      }
                      value={start}
                      placeholder={hospData?.hospital_timing.split(" - ")[0]}
                      className="w-full text-sm md:text-base"
                    />
                    <p className="text-docDetail">to</p>
                    <ReactDropdown
                      options={generateTimeOptions("morningEnd")}
                      onChange={(selectedOption) =>
                        setEnd(selectedOption.value)
                      }
                      value={end}
                      placeholder={hospData?.hospital_timing.split(" - ")[1]}
                      className="w-full text-sm md:text-base"
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <p className="font-light text-modalText text-sm mb-1">
                    Phone Number
                  </p>
                  <input
                    className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                    placeholder="Enter phone number"
                    type="text"
                    value={phno}
                    onChange={(e) => setPhno(e.target.value)}
                    minLength={12}
                    maxLength={12}
                    disabled
                  />
                </div>
                <div className="mt-3">
                  <p className="font-light text-modalText text-sm mb-1">
                    Email Id
                  </p>
                  <input
                    className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                    placeholder="Enter email id"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mt-3">
                  <p className="font-light text-modalText text-sm mb-1">
                    Address
                  </p>
                  <input
                    className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                    placeholder="Enter address"
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    maxLength={70}
                  />
                </div>
              </div>
            ) : (
              <HospDetails />
            )}
          </div>

          {/* Footer */}
          <div className="flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
            {edit && (
              <WhiteButton
                name="Cancel"
                handleClick={() => setEdit(false)}
                className="mx-6 my-3"
              />
            )}

            {edit && (
              <DarkBlueButton name="Save" type="submit" className="mr-6 my-3" />
            )}
          </div>
        </form>
        <div className="flex justify-end mr-6">
          {!edit && (
            <DarkBlueButton
              name="Edit"
              handleClick={() => setEdit(true)}
              className="my-3"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default HospModal;
