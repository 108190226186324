import React, { useEffect, useState } from "react";
import Header from "../components/organisms/Header";
import Tabs from "../components/molecules/Tabs";
import Table from "../components/organisms/Table";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import Loader from "../components/molecules/Loader";
import { getRouteSegment } from "../lib/utils/funcs";
import { Search } from "../assets/icons/Icons";
import { getBookingListByHospitalId } from "../lib/apis/booking";
import { usePatientBooking } from "../lib/contexts/PatientBookingContext";
import { hitRefreshToken } from "../lib/apis/user";
import { getCookie, setCookie } from "../lib/utils/cookies";
import { useUserData } from "../lib/contexts/UserContext";

const Appointments = () => {
  const id = getRouteSegment(1);
  const accessToken = String(getCookie("accessToken"));
  const refreshToken = String(getCookie("refreshToken"));

  const { userData } = useUserData();
  const { hospitalID, setHospitalID, hospData } = useHospDocData();
  const { appointmentsData, setAppointmentsData } = usePatientBooking();
  const [search, setSearch] = useState("");
  console.log("appointmentsData", appointmentsData);

  useEffect(() => {
    setHospitalID(id);
  }, [id]);

  useEffect(() => {
    const patientsData = async () => {
      const res = await getBookingListByHospitalId(hospitalID);
      if (res?.status === 200) setAppointmentsData(res.data.result);
      else if (res?.status === 401) {
        const refresh_data = await hitRefreshToken(accessToken, refreshToken);
        if (refresh_data?.status === 200) {
          console.log("Refresh");
          setCookie("accessToken", refresh_data.data.result.access_token, 30);
          setCookie("refreshToken", refresh_data.data.result.refresh_token, 30);
          const res = await getBookingListByHospitalId(hospitalID);
          if (res?.status === 200) setAppointmentsData(res.data.result);
        } else {
          setAppointmentsData(undefined);
        }
      }
    };

    patientsData();
  }, [, hospData]);

  const appointmentHeaders = [
    "Token No.",
    "Name",
    "Doctor",
    "Contact Details",
    "Status",
  ];

  const tabsConfig = [
    {
      label: "Today's Appointments",
      count:
        "(" +
        (appointmentsData?.filter(
          (item) =>
            item.reference_id.toLowerCase().includes(search.toLowerCase()) ||
            item.patient_full_name
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            item.patient_contact_number.includes(search.toLowerCase())
        ).length || "0") +
        ")",
      content:
        appointmentsData?.filter(
          (item) =>
            item.reference_id.toLowerCase().includes(search.toLowerCase()) ||
            item.patient_full_name
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            item.patient_contact_number.includes(search.toLowerCase())
        ).length === 0 || appointmentsData === undefined ? (
          <div className="bg-white flex justify-center items-center min-h-[75vh]">
            <p className="text-center">No Appointments Found.</p>
          </div>
        ) : (
          <div className="max-h-[65vh] md:max-h-[80vh] lg:max-h-[70vh] overflow-y-auto">
            <Table
              type="appointments"
              headers={appointmentHeaders}
              bookings={
                search
                  ? appointmentsData?.filter(
                      (item) =>
                        item.reference_id
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item.patient_full_name
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item.patient_contact_number.includes(
                          search.toLowerCase()
                        )
                    )
                  : appointmentsData
              }
            />
          </div>
        ),
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <Header header value={"Appointments"} />
      <div className="flex flex-col bg-white rounded-t-lg mt-6 ml-7 mr-8 pt-4 border-[0.5px] border-doctorsBorder">
        <Tabs
          tabsConfig={tabsConfig}
          rightSide={
            <div className="flex items-center text-sm m-3 md:mt-0 md:mb-[7px]">
              <div className="flex items-center rounded-lg border-[0.5px] border-sbBorder w-full bg-white md:mr-5">
                <input
                  className="border-0 p-0 px-4 py-2 border-sbBorder opacity-80 rounded-lg focus:outline-none w-5/6"
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <p className="w-1/6">
                  <Search />
                </p>
              </div>
            </div>
          }
        />
      </div>
      {userData === undefined && <Loader />}
    </div>
  );
};

export default Appointments;
