export const docListHeaders = ["Name", "Contact Details", "Action"];

export const doctorHeaders = [
  "Name",
  // "Status",
  // "Availability",
  "Specialized",
  "Contact Details",
  "Action",
];

export const inclinicHeaders = [
  "Token No.",
  "Name",
  "Contact Details",
  "Checked-In At",
  "Status",
];

export const bookedHeaders = [
  "Check-In OTP",
  "Name",
  "Contact Details",
  "Scheduled On",
  "Slot Timings",
  "Action",
];

export const completedHeaders = [
  "Name",
  "Contact Details",
  "Completed On",
  "Action",
];

export const cancelledHeaders = [
  "Name",
  "Contact Details",
  "Scheduled On",
  "Cancelled On",
];

export const dashAppointmentHeaders = [
  "Patient Name",
  "Doctor",
  "Contact Details",
];

export const appointmentHeaders = [
  "Check-In OTP",
  "Name",
  "Slot Timings",
  "Doctor",
  "Contact Details",
  "Status",
];

export const patientHeaders = [
  "Name",
  "Contact Details",
  "Scheduled On",
  "Action",
];
