import React from "react";

const WhiteButton = ({
  name,
  handleClick,
  className,
}: {
  name: string;
  handleClick: (e?: any) => void;
  className?: string;
}) => {
  return (
    <button
      className={`rounded-lg opacity-80 px-4 py-2 hover:bg-sbText border-[0.5px] border-sbBorder mr-3 focus:outline-none ${
        className ? className : ""
      }`}
      onClick={handleClick}
      type="button"
    >
      {name}
    </button>
  );
};

export default WhiteButton;
