import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import DarkBlueButton from "../../atoms/buttons/DarkBlueButton";
import WhiteButton from "../../atoms/buttons/WhiteButton";
import CloseButton from "../../atoms/buttons/CloseButton";
import { Patient } from "../../../lib/utils/types";
import moment from "moment";
import {
  createPatient,
  getPatientsByHospitalID,
  updatePatient,
} from "../../../lib/apis/patient";
import { toast } from "react-toastify";
import { usePatientBooking } from "../../../lib/contexts/PatientBookingContext";
import { getRouteSegment } from "../../../lib/utils/funcs";

const EditPatientModal = ({
  closeModal,
  modalIsOpen,
  customStyles,
  patient,
  page,
  pageSize,
  search,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
  patient: Patient | undefined;
  page: number;
  pageSize: number;
  search: string;
}) => {
  const hospital_id = getRouteSegment(1);
  const { setPatientsData } = usePatientBooking();

  const [patientState, setPatientState] = useState({
    full_name: patient?.full_name,
    contact_number: patient?.contact_number,
    gender: patient?.gender,
    email: patient?.email,
    address: patient?.address,
    date_of_birth: patient?.date_of_birth,
    patient_id: patient?.patient_id,
  });

  useEffect(() => {
    if (patient) setPatientState(patient);
  }, [modalIsOpen]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (
      patientState.full_name &&
      patientState.contact_number &&
      patientState.gender &&
      patientState.full_name !== "" &&
      !isNaN(Number(patientState.contact_number)) &&
      String(patientState.contact_number).length === 12 &&
      patientState.patient_id
    ) {
      closeModal();

      const req = {
        full_name: patientState.full_name,
        contact_number: patientState.contact_number,
        gender: patientState.gender,
        email: patientState.email || "",
        date_of_birth:
          patientState.date_of_birth || moment().format("YYYY-MM-DD"),
        address: patientState.address || "",
      };

      const res = await updatePatient(patientState.patient_id, req);
      console.log(res);
      if (res?.status === 200) {
        toast.success("Updated successfully!");
        const res = await getPatientsByHospitalID(
          hospital_id,
          page,
          pageSize,
          search
        );
        if (res?.status === 200) setPatientsData(res.data.result);
      }
    } else if (patientState.full_name === "") {
      toast.error("Please enter patient name.");
    } else if (patientState.gender === "") {
      toast.error("Please enter patient gender.");
    } else if (patientState.contact_number === "") {
      toast.error("Please enter patient phone number.");
    } else {
      toast.error("Invalid inputs.");
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      {/* Header */}
      <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
        <p className="text-dark text-md">Edit Patient Details</p>
        <CloseButton handleClick={() => closeModal()} />
      </div>

      {/* Body */}
      <form onSubmit={handleSubmit} className="mb-0">
        <div className="p-0 max-h-96 overflow-y-auto">
          <div className="px-6 py-5 bg-lightGrey">
            <div className="flex flex-col w-full">
              <p className="font-light text-modalText text-sm mb-1">
                Full Name
              </p>
              <input
                className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                placeholder="Enter full name"
                value={patientState.full_name}
                onChange={(e) =>
                  setPatientState((prevState) => ({
                    ...prevState,
                    full_name: e.target.value,
                  }))
                }
              />
            </div>
            <div className="flex flex-col w-full mt-3">
              <p className="font-light text-modalText text-sm mb-1">
                Phone Number
              </p>
              <input
                className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                placeholder="Enter phone number"
                value={patientState.contact_number}
                onChange={(e) =>
                  setPatientState((prevState) => ({
                    ...prevState,
                    contact_number: e.target.value,
                  }))
                }
                minLength={12}
                maxLength={12}
              />
            </div>
            <div className="flex flex-row mt-3">
              <div className="flex flex-col w-full mr-5">
                <p className="font-light text-modalText text-sm mb-1 ">
                  Date of Birth
                </p>
                <input
                  className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                  placeholder="Enter date of birth"
                  type="date"
                  value={moment(patientState?.date_of_birth).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={(e) =>
                    setPatientState((prevState) => ({
                      ...prevState,
                      date_of_birth: e.target.value,
                    }))
                  }
                  min="1954-01-01"
                  max={`${moment().format("YYYY-MM-DD")}`}
                />
              </div>
              <div className="flex flex-col w-full">
                <p className="font-light text-modalText text-sm mb-1">Gender</p>
                <div className="flex flex-row space-x-3">
                  <button
                    className={`${
                      patientState.gender?.toLowerCase() === "male"
                        ? "bg-editBG text-white"
                        : "border-[1px] border-editBG text-editBG hover:bg-purple-400 hover:text-white"
                    } px-4 py-2 rounded-[20px]`}
                    onClick={() =>
                      setPatientState((prevState) => ({
                        ...prevState,
                        gender: "Male",
                      }))
                    }
                    type="button"
                  >
                    Male
                  </button>
                  <button
                    className={`${
                      patientState.gender?.toLowerCase() === "female"
                        ? "bg-editBG text-white"
                        : "border-[1px] border-editBG text-editBG hover:bg-purple-400 hover:text-white"
                    } px-4 py-2 rounded-[20px]`}
                    onClick={() =>
                      setPatientState((prevState) => ({
                        ...prevState,
                        gender: "Female",
                      }))
                    }
                    type="button"
                  >
                    Female
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full mt-3">
              <p className="font-light text-modalText text-sm mb-1">Email</p>
              <input
                type="email"
                className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                placeholder="Enter email address"
                value={patientState.email}
                onChange={(e) =>
                  setPatientState((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }))
                }
              />
            </div>
            <div className="flex flex-col w-full mt-3">
              <p className="font-light text-modalText text-sm mb-1">Address</p>
              <input
                className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                placeholder="Enter address"
                value={patientState.address}
                onChange={(e) =>
                  setPatientState((prevState) => ({
                    ...prevState,
                    address: e.target.value,
                  }))
                }
              />
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
          <WhiteButton name="Cancel" handleClick={() => closeModal()} />
          <DarkBlueButton
            name="Save"
            type="submit"
            handleClick={handleSubmit}
          />
        </div>
      </form>
    </Modal>
  );
};

export default EditPatientModal;
