import React from "react";
import { DocProfileData } from "../../services/types";
import moment from "moment";
import { Option } from "react-dropdown";
import OverflowString from "../atoms/OverflowString";
import { usePatientBooking } from "../../lib/contexts/PatientBookingContext";

const DocDetailsCard = ({
  docData,
  sessions,
}: {
  docData: DocProfileData | undefined;
  sessions: Option[] | undefined;
}) => {
  const { SelectedDate } = usePatientBooking();
  return (
    <div className="bg-white px-6 py-5 hidden md:flex flex-row justify-between rounded-lg md:w-1/3 h-40">
      <div className="flex flex-col mr-8">
        <p className="font-semibold mb-3 text-sm">Doctor Availability</p>
        <div className="text-sm lg:text-xs xl:text-sm mb-1">
          {sessions?.length === 0
            ? "No sessions today"
            : sessions?.map((timings, index) => (
                <p key={index}>{timings.label}</p>
              ))}
        </div>
        <p className="text-xs text-docDetail ">
          {moment(SelectedDate, "YYYY-MM-DD").format("dddd")}
        </p>
      </div>
      <div className="flex flex-col">
        <p className="font-semibold mb-3 text-sm">Contact Details</p>
        <p className="text-sm mb-1 ">{docData?.contact_number?.substring(2)}</p>
        <OverflowString text={docData?.email} length={10} />
      </div>
    </div>
  );
};

export default DocDetailsCard;
