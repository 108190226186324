import React, { useEffect, useState } from "react";
import {
  AvailabilitySlot,
  DocAvailabilityData,
  EditDaySessions,
} from "../../services/types";
import moment from "moment";
import EditSessionModal from "./modals/EditSessionModal";
import { modalStyles } from "../../assets/styles/modalStyles";
import EachDaySession from "../molecules/EachDaySession";
import { toast } from "react-toastify";
import { useUserData } from "../../lib/contexts/UserContext";
import { getRouteSegment } from "../../lib/utils/funcs";
import { adminAccess } from "../../lib/utils/constants";

const SessionInfo = ({
  docAvail,
  setDocAvail,
}: {
  docAvail: AvailabilitySlot[] | undefined;
  setDocAvail: React.Dispatch<
    React.SetStateAction<DocAvailabilityData[] | undefined>
  >;
}) => {
  const { userData } = useUserData();
  const hospital_id = getRouteSegment(1);
  const [visible, setVisible] = useState(false);

  const week = [
    "",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const defaultDaySessions: EditDaySessions = {
    sessions: {
      morning: {
        availability_id: "",
        day_of_week: 0,
        start_time: "",
        end_time: "",
        max_patients_per_slot: 0,
        wait_time: { minutes: 0 },
        selected: false,
        max_wa_tokens: null,
        queue_type: "",
      },
      afternoon: {
        availability_id: "",
        day_of_week: 0,
        start_time: "",
        end_time: "",
        max_patients_per_slot: 0,
        wait_time: { minutes: 0 },
        selected: false,
        max_wa_tokens: null,
        queue_type: "",
      },
      evening: {
        availability_id: "",
        day_of_week: 0,
        start_time: "",
        end_time: "",
        max_patients_per_slot: 0,
        wait_time: { minutes: 0 },
        selected: false,
        max_wa_tokens: null,
        queue_type: "",
      },
    },
    selected: false,
  };

  const [weekSchedule, setWeekSchedule] = useState<{
    [key: number]: EditDaySessions;
  }>({
    1: {
      ...defaultDaySessions,
      sessions: {
        morning: {
          ...defaultDaySessions.sessions.morning,
          day_of_week: 1,
          selected: false,
        },
        afternoon: {
          ...defaultDaySessions.sessions.afternoon,
          day_of_week: 1,
          selected: false,
        },
        evening: {
          ...defaultDaySessions.sessions.evening,
          day_of_week: 1,
          selected: false,
        },
      },
      selected: false,
    },
    2: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 2 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 2 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 2 },
      },
      selected: false,
    },
    3: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 3 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 3 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 3 },
      },
      selected: false,
    },
    4: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 4 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 4 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 4 },
      },
      selected: false,
    },
    5: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 5 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 5 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 5 },
      },
      selected: false,
    },
    6: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 6 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 6 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 6 },
      },
      selected: false,
    },
    7: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 7 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 7 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 7 },
      },
      selected: false,
    },
  });

  const setData = () => {
    const temp: {
      [key: number]: EditDaySessions;
    } = {
      1: {
        ...defaultDaySessions,
        sessions: {
          morning: {
            ...defaultDaySessions.sessions.morning,
            day_of_week: 1,
            selected: false,
          },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 1,
            selected: false,
          },
          evening: {
            ...defaultDaySessions.sessions.evening,
            day_of_week: 1,
            selected: false,
          },
        },
        selected: false,
      },
      2: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 2 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 2,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 2 },
        },
        selected: false,
      },
      3: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 3 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 3,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 3 },
        },
        selected: false,
      },
      4: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 4 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 4,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 4 },
        },
        selected: false,
      },
      5: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 5 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 5,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 5 },
        },
        selected: false,
      },
      6: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 6 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 6,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 6 },
        },
        selected: false,
      },
      7: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 7 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 7,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 7 },
        },
        selected: false,
      },
    };
    if (docAvail !== undefined)
      for (const availability of docAvail) {
        const {
          availability_id,
          day_of_week,
          start_time,
          end_time,
          max_patients_per_slot,
          wait_time,
        } = availability;

        if (temp[day_of_week]) {
          if (
            moment(start_time, "HH:mm:ss").isBetween(
              moment("05:59:00", "HH:mm:ss"),
              moment("11:59:00", "HH:mm:ss")
            )
          ) {
            temp[day_of_week].sessions.morning.availability_id =
              availability_id;
            temp[day_of_week].sessions.morning.start_time = start_time;
            temp[day_of_week].sessions.morning.end_time = end_time;
            temp[day_of_week].sessions.morning.max_patients_per_slot =
              max_patients_per_slot;
            temp[day_of_week].sessions.morning.wait_time = wait_time;
          } else if (
            moment(start_time, "HH:mm:ss").isBetween(
              moment("11:59:00", "HH:mm:ss"),
              moment("16:59:00", "HH:mm:ss")
            )
          ) {
            temp[day_of_week].sessions.afternoon.availability_id =
              availability_id;
            temp[day_of_week].sessions.afternoon.start_time = start_time;
            temp[day_of_week].sessions.afternoon.end_time = end_time;
            temp[day_of_week].sessions.afternoon.max_patients_per_slot =
              max_patients_per_slot;
            temp[day_of_week].sessions.afternoon.wait_time = wait_time;
          } else {
            temp[day_of_week].sessions.evening.availability_id =
              availability_id;
            temp[day_of_week].sessions.evening.start_time = start_time;
            temp[day_of_week].sessions.evening.end_time = end_time;
            temp[day_of_week].sessions.evening.max_patients_per_slot =
              max_patients_per_slot;
            temp[day_of_week].sessions.evening.wait_time = wait_time;
          }
        }
      }

    setWeekSchedule({ ...temp });
  };

  useEffect(() => {
    // console.log("changed", docAvail);
    setData();
  }, [docAvail]);

  return (
    <div className="bg-white flex flex-col mt-6 rounded-lg mb-10">
      <div className="flex flex-row justify-between items-center rounded-t-lg border-[1px] border-b-doctorsBorder px-5 py-4">
        <p className="text-dark font-medium">Session Details</p>
        {/* {(!adminAccess.includes(hospital_id) ||
          userData?.role === "MasterAdmin") && ( */}
        {(userData?.user_id === "ae5b1dd2-b1a3-4969-acee-197d614c5e89" ||
          userData?.user_id === "20b93d12-e456-4a9d-ba84-5017d305a093") && (
          <button
            className="bg-editBG text-white px-5 py-[6px] rounded-[20px]"
            onClick={() => {
              if (docAvail !== undefined) setVisible(true);
              else toast.error("Sessions data loading.");
            }}
          >
            Edit
          </button>
        )}
        {/* )} */}
      </div>
      <div className="rounded-b-lg px-4 border-[1px] border-t-0 border-doctorsBorder">
        {Object.keys(weekSchedule).map((dayOfWeek) => (
          <EachDaySession
            day={week[Number(dayOfWeek)]}
            daySchedule={weekSchedule[Number(dayOfWeek)]}
          />
        ))}
      </div>
      <EditSessionModal
        modalIsOpen={visible}
        closeModal={() => setVisible(false)}
        customStyles={modalStyles}
        docAvail={docAvail}
        setDocAvail={setDocAvail}
      />
    </div>
  );
};

export default SessionInfo;
