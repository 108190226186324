import React, { useEffect, useState } from "react";
import Header from "../components/organisms/Header";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Table from "../components/organisms/Table";
import { dashAppointmentHeaders, docListHeaders } from "../assets/data/headers";
import Welcome from "../components/molecules/Welcome";
import DashboardCard from "../components/atoms/DashboardCard";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import Loader from "../components/molecules/Loader";
import { getRouteSegment } from "../lib/utils/funcs";
import { useUserData } from "../lib/contexts/UserContext";
import { usePatientBooking } from "../lib/contexts/PatientBookingContext";
import { getDoctorListByHospitalId } from "../lib/apis/doctor";
import { getBookingListByHospitalId } from "../lib/apis/booking";

const Dashboard = () => {
  const navigate = useNavigate();
  const { userData } = useUserData();
  const { hospitalID, setHospitalID, doctorsData, setDoctorsData } =
    useHospDocData();
  const { appointmentsData, setAppointmentsData } = usePatientBooking();

  const id = getRouteSegment(1);
  const [todaysBookings, setTodaysBookings] = useState<number>();

  useEffect(() => {
    setHospitalID(id);
  }, [id]);

  useEffect(() => {
    const fetchdoctorsData = async () => {
      const res = await getDoctorListByHospitalId(hospitalID);
      setDoctorsData(undefined);
      if (res?.status === 200) {
        console.log("doctorsData", res.data.result);
        setDoctorsData(res.data.result);
      } else console.log(res.data.error);
    };

    const fetchTodaysBookings = async () => {
      const res = await getBookingListByHospitalId(hospitalID);
      setTodaysBookings(undefined);
      setAppointmentsData(undefined);
      if (res?.status === 200) {
        console.log("appointmentsData", res.data.result);
        setTodaysBookings(res.data.result.length);
        setAppointmentsData(res.data.result);
      } else console.log(res.data.error);
    };
    fetchTodaysBookings();
    fetchdoctorsData();
  }, [hospitalID, userData]);

  return (
    <div className="flex flex-col w-full">
      <Header header value={"Dashboard"} />
      <div className="flex flex-col lg:flex-row mt-7 px-7 lg:pr-0">
        <div className="flex flex-col w-full lg:w-1/2">
          <Welcome />
          <div className="flex flex-col my-5">
            <div className="flex flex-col md:flex-row mb-4 space-y-[10px] md:space-x-[10px] md:space-y-0">
              <DashboardCard
                icon={
                  <img
                    src={require("../assets/images/patients.png")}
                    alt="patients"
                    className="md:w-12 lg:w-14"
                  />
                }
                value={
                  todaysBookings === undefined ? "0" : String(todaysBookings)
                }
                name={"Today's Bookings"}
              />
              <DashboardCard
                icon={
                  <img
                    src={require("../assets/images/doctors.png")}
                    alt="doctor"
                    className="md:w-10 lg:w-14"
                  />
                }
                value={
                  doctorsData === undefined ? "0" : String(doctorsData?.length)
                }
                name={"Available Doctors"}
              />
            </div>
          </div>
        </div>
        <div className="lg:ml-5 lg:mr-7 lg:w-1/2">
          <div className="flex flex-row justify-between items-center bg-white px-5 py-4 rounded-lg">
            <p className="text-dark font-medium text-sm lg:text-base">
              Doctor List
            </p>
            <button
              className="text-sbTextHover text-sm"
              onClick={() => navigate("/" + hospitalID + "/doctors")}
            >
              View All
            </button>
          </div>
          {doctorsData === undefined ? (
            <div className="bg-white flex justify-center items-center h-40">
              <p className="text-center">No Doctors Found.</p>
            </div>
          ) : (
            <Table
              type="doclist"
              headers={docListHeaders}
              docData={doctorsData.sort(
                (a, b) =>
                  moment(a.created_at).valueOf() -
                  moment(b.created_at).valueOf()
              )}
            />
          )}
        </div>
      </div>
      <div className="mt-5 px-7">
        <div className="flex flex-row justify-between items-center bg-white px-5 py-4 rounded-lg">
          <p className="text-dark font-medium text-sm lg:text-base">
            Today's Appointments
          </p>
          <button
            className="text-sbTextHover text-sm"
            onClick={() => navigate("/" + hospitalID + "/appointments")}
          >
            View All
          </button>
        </div>
        {appointmentsData === undefined ? (
          <div className="bg-white flex justify-center items-center h-40">
            <p className="text-center">No Appointments Found.</p>
          </div>
        ) : (
          <Table
            type="dashapps"
            headers={dashAppointmentHeaders}
            bookings={appointmentsData?.slice(0, 2)}
          />
        )}
      </div>
      {userData === undefined && <Loader />}
    </div>
  );
};

export default Dashboard;
