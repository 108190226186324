import React, { useEffect, useState } from "react";
import { usePatientBooking } from "../../lib/contexts/PatientBookingContext";

type TabsProps = {
  tabsConfig: any;
  rightSide?: any;
  defaultIndex?: number;
};

const Tabs: React.FC<TabsProps> = ({ tabsConfig, rightSide, defaultIndex }) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex ?? 0);
  const { SelectedDate } = usePatientBooking();

  useEffect(() => {
    setSelectedIndex(0);
  }, [SelectedDate]);

  const handleClick = (index: number) => setSelectedIndex(index);
  return (
    <>
      <div className="flex flex-col md:flex-row justify-between shadow-sm">
        <div className="flex flex-wrap md:w-3/5 pl-5 space-x-6 text-queueText text-sm font-medium text-center overflow-x-auto max-h-[43px]">
          {tabsConfig.map((tab: any, index: number) => (
            <button
              key={`tab-${index}`}
              onClick={() => handleClick(index)}
              className={
                selectedIndex === index
                  ? "inline-block pt-2 px-1 pb-5 text-queueHover border-b-[1px] border-queueHover rounded-t-lg"
                  : "inline-block pt-2 px-1 pb-5 border-b-[1px] border-transparent rounded-t-lg hover:text-gray-600 hover:border-queueText"
              }
            >
              {tab.label}
              {tab.count}
            </button>
          ))}
        </div>
        <div className="md:w-2/5 flex md:justify-end">{rightSide}</div>
      </div>
      <div>
        {tabsConfig.map((tab: any, index: number) => (
          <div key={`tabpanel-${index}`} hidden={selectedIndex !== index}>
            {tab.content}
          </div>
        ))}
      </div>
    </>
  );
};

export default Tabs;
