import React from "react";
import { useHospDocData } from "../../lib/contexts/HospitalDoctorContext";
import ProfilePicture from "../atoms/ProfilePicture";
import moment from "moment";

const Welcome = () => {
  const { hospData } = useHospDocData();
  const currentDate = moment().format("DD MMM YYYY");

  return (
    <div className="flex flex-row items-center p-5 bg-welcomeBG rounded-lg">
      {hospData?.logo === "test" ? (
        <ProfilePicture
          username={hospData.hospital_name}
          className="mr-3 lg:mr-0"
        />
      ) : (
        <img
          className={`w-10 h-10 mx-2 rounded-full`}
          src={hospData?.logo}
          alt="hospital-logo"
        ></img>
      )}
      <div className="md:ml-4 lg:ml-6">
        <div className="flex flex-row lg:space-x-1 mb-1">
          <p className="text-white lg:text-2xl tracking-[0.4px]">
            Welcome back,{" "}
            <span className=" font-semibold">
              {hospData === undefined ? "" : hospData?.hospital_name}
            </span>
          </p>
        </div>
        <p className="text-white text-[10px] lg:text-xs tracking-[0.2px]">
          {currentDate}
        </p>
      </div>
    </div>
  );
};

export default Welcome;
