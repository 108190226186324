import React from "react";

const DashboardCard = ({
  icon,
  value,
  name,
}: {
  icon: JSX.Element;
  value: string;
  name: string;
}) => {
  return (
    <div className="flex flex-row items-center w-full lg:w-1/2 bg-white rounded-lg px-4 py-5 lg:px-5 lg:py-6">
      {icon}
      <div className="ml-6">
        <p className="text-lg lg:text-2xl font-medium text-dark tracking-[0.4px]">
          {value}
        </p>
        <p className="text-sm lg:text-xs text-dbGray tracking-[0.2px]">
          {name}
        </p>
      </div>
    </div>
  );
};

export default DashboardCard;
