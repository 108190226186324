import React from "react";

const SelectToken = ({
  selected,
  setToken,
  item,
}: {
  selected: boolean;
  setToken: (value: React.SetStateAction<number | undefined>) => void;
  item: number;
}) => {
  const handleTokenClick = () => {
    if (item !== undefined && typeof item === "number") {
      console.log(item);
      setToken((prevToken) => {
        // Assuming 'people' is still relevant and determines whether to set or reset the token
        if (prevToken === undefined) {
          return item; // Set the new token if there was no previous token
        } else {
          // Compare the new token with the previous one; this logic may change depending on your requirements
          return prevToken === item ? undefined : item; // Toggle the token or update it
        }
      });

      // For debugging
      // console.log(`Token updated to: ${item}`);
    }
  };

  if (selected && item) {
    return (
      <button
        className="min-w-52 mb-3 md:mb-0 bg-editBG text-whiteborder-[1px] border-editBG text-white
 px-4 py-2 rounded-[20px] flex flex-col items-center"
        type="button"
      >
        {/* <p>
          {item &&
            `${item.map((token) => token.time)[0].split(" - ")[0]} - ${
              item.map((token) => token.time)[item.length - 1].split(" - ")[1]
            }`}
        </p> */}
        <p>Token {item}</p>
      </button>
    );
  }
  return (
    <button
      className="min-w-52 mb-3 md:mb-0 border-[1px] border-editBG text-editBG px-4 py-2 rounded-[20px] flex flex-col items-center hover:bg-purple-400 hover:text-white"
      onClick={handleTokenClick}
      type="button"
    >
      {/* <p>
        {item.length !== 0 &&
          `${item.map((token) => token.time)[0].split(" - ")[0]} - ${
            item.map((token) => token.time)[item.length - 1].split(" - ")[1]
          }`}
      </p> */}
      <p>Token {item}</p>
    </button>
  );
};

export default SelectToken;
