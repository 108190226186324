// import React, { useEffect, useState } from "react";
// import Header from "../components/organisms/Header";
// import BasicInfo from "../components/molecules/BasicInfo";
// import SessionInfo from "../components/organisms/SessionInfo";
// import { DocAvailabilityData } from "../services/types";
// import { getDoctorAvailability } from "../services/apis";
// import { useHospDocData } from "../lib/HospitalContext";
// import { useParams } from "react-router-dom";
// import Loader from "../components/molecules/Loader";
// import { getRouteSegment } from "../lib/funcs";

// const DocDetails = () => {
//   const [docAvail, setDocAvail] = useState<DocAvailabilityData[]>();

//   const id = getRouteSegment(1);
//   const param = useParams();
//   const mapping_id = String(param.mapping_id);
//   const { docDetails, setDocDetails, childHospData, setChildHospitalID } =
//     useHospDocData();

//   useEffect(() => {
//     setChildHospitalID(id);
//   }, [id]);

//   useEffect(() => {
//     const fetchDocData = async () => {
//       const res = await getDoctorAvailability(mapping_id);
//       if (res?.status === 200) {
//         setDocAvail(res.data.result.doctor_availability);
//         setDocDetails(res.data.result.doctor_details);
//       }
//     };
//     fetchDocData();
//   }, [mapping_id, childHospData]);

//   return (
//     <div className="flex flex-col w-full">
//       <Header docData={docDetails} />
//       <div className="pt-6 px-7">
//         <BasicInfo data={docDetails} setData={setDocDetails} />
//         <SessionInfo docAvail={docAvail} setDocAvail={setDocAvail} />
//       </div>
//       {childHospData === undefined && <Loader />}
//     </div>
//   );
// };

// export default DocDetails;

import React, { useEffect, useState } from "react";
import Header from "../components/organisms/Header";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import { useParams } from "react-router-dom";
import { DocAvailabilityData } from "../services/types";
import BasicInfo from "../components/molecules/BasicInfo";
import SessionInfo from "../components/organisms/SessionInfo";
import Loader from "../components/molecules/Loader";
import { useUserData } from "../lib/contexts/UserContext";
import { getDoctorAvailability } from "../lib/apis/doctor";

const DocDetails = () => {
  const [docAvail, setDocAvail] = useState<DocAvailabilityData[]>();
  const { userData } = useUserData();

  const param = useParams();
  const mapping_id = String(param.mapping_id);
  const { docDetails, setDocDetails } = useHospDocData();
  // console.log("docDetails", docDetails);

  // useEffect(() => {
  //   setChildHospitalID(id);
  // }, [id]);

  useEffect(() => {
    const fetchDocData = async () => {
      const res = await getDoctorAvailability(mapping_id);
      if (res?.status === 200) {
        setDocAvail(res.data.result.doctor_availability);
        setDocDetails(res.data.result.doctor_details);
      }
    };
    fetchDocData();
  }, [mapping_id, setDocDetails]);
  return (
    <div className="flex flex-col w-full">
      <Header docData={docDetails} />
      <div className="pt-6 px-7">
        <BasicInfo data={docDetails} setData={setDocDetails} />
        <SessionInfo docAvail={docAvail} setDocAvail={setDocAvail} />
      </div>
      {userData === undefined && <Loader />}
    </div>
  );
};

export default DocDetails;
