import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { Option } from "react-dropdown";
import moment from "moment";
import { useParams } from "react-router-dom";
import DarkBlueButton from "../../atoms/buttons/DarkBlueButton";
import WhiteButton from "../../atoms/buttons/WhiteButton";
import CloseButton from "../../atoms/buttons/CloseButton";
import SelectSlot from "../../atoms/SelectSlot";
import { useHospDocData } from "../../../lib/contexts/HospitalDoctorContext";
import { LeftArrow, RightArrow } from "../../../assets/icons/Icons";
import { estimateVisitTime, trialClinics } from "../../../lib/utils/constants";
import { usePatientBooking } from "../../../lib/contexts/PatientBookingContext";
import {
  addFollowUp,
  getBookingListByAvailabilityId,
  getSlots,
  getTokens,
} from "../../../lib/apis/booking";
import { getDoctorAvailability } from "../../../lib/apis/doctor";
import { DocAvailability } from "../../../lib/utils/types";
import { hitRefreshToken } from "../../../lib/apis/user";
import { getCookie, setCookie } from "../../../lib/utils/cookies";
import { useUserData } from "../../../lib/contexts/UserContext";
import NotFound from "../../atoms/buttons/NotFound";
import SelectToken from "../../atoms/SelectToken";
import { getRouteSegment } from "../../../lib/utils/funcs";

const FollowUpModal = ({
  closeModal,
  modalIsOpen,
  customStyles,
  booking_id,
  session,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
  booking_id: string | undefined;
  session: Option | undefined;
}) => {
  const { userData } = useUserData();
  const hospital_id = getRouteSegment(1);
  const { setBookings, SelectedDate } = usePatientBooking();

  const [bookingDate, setBookingDate] = useState(SelectedDate);
  const [docSession, setDocSession] = useState<Option | undefined>();
  const [bookingSlot, setBookingSlot] = useState<Array<string>>();
  const [allSlots, setAllSlots] = useState<Array<string>>([""]);
  const [slicedSlots, setSlicedSlots] = useState<Array<string>>([""]);
  const [allSessions, setAllSessions] = useState<Option[] | undefined>();
  const [index, setIndex] = useState<number | undefined>(
    moment(SelectedDate).day() + 1
  );
  const [comments, setComments] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [queue_type, setQueue_type] = useState(
    allSessions?.filter((item) => item.value === docSession?.value)[0]?.data
      ?.queue_type
  );

  const param = useParams();
  const mapping_id = String(param.mapping_id);

  const showNext = () => {
    // Ensure we don't go beyond the length of the data array
    console.log(currentIndex + 10, allSlots.length);
    if (currentIndex + 10 < allSlots.length) {
      setCurrentIndex(currentIndex + 10);
    }
  };

  // Function to show the previous 10 items
  const showPrevious = () => {
    // Ensure we don't go below 0
    if (currentIndex - 10 >= 0) {
      setCurrentIndex(currentIndex - 10);
    }
  };

  const showSlots = useCallback(async () => {
    if (!mapping_id || !docSession) return;
    // console.log(session);
    const [seshStart, seshEnd] = String(docSession?.label).split(" - ");

    const data = {
      mapping_id: mapping_id,
      booked_date: SelectedDate,
      number_of_people: 1,
      availability_id: docSession.value,
      start_time: moment(seshStart, "hh:mmA").format("HH:mm:ss"),
      end_time: moment(seshEnd, "hh:mmA").format("HH:mm:ss"),
    };
    const slots_data = await getSlots(data);
    // console.log(slots_data?.data);

    if (slots_data?.status === 200 && slots_data.data.result.length !== 0) {
      const api_data: { start: string; end: string }[] = slots_data.data.result;

      // console.log("API Data:", api_data);

      const allSlots =
        bookingDate === moment().format("YYYY-MM-DD")
          ? api_data
              .filter((docSession) =>
                moment(docSession.end, "HH:mm").isAfter(moment())
              )
              .map((docSession) => `${docSession.start} - ${docSession.end}`)
          : api_data.map(
              (docSession) => `${docSession.start} - ${docSession.end}`
            );

      // console.log("All Slots:", allSlots);

      const slicedData = allSlots.slice(currentIndex, currentIndex + 10);

      // console.log("Sliced Data:", slicedData);

      if (allSlots.length !== 0) {
        setAllSlots(allSlots);
        setSlicedSlots(slicedData);
        setBookingSlot(slicedData.slice(0, 1));
      }
    } else if (slots_data?.data.length === 0) {
      setAllSlots([""]);
    }
  }, [mapping_id, docSession, SelectedDate, currentIndex]);

  useEffect(() => {
    showSlots();
    setQueue_type(
      allSessions?.filter((item) => item.value === docSession?.value)[0]?.data
        ?.queue_type
    );
  }, [modalIsOpen, docSession, showSlots]);

  useEffect(() => {
    setBookingDate(moment().format("YYYY-MM-DD"));
    setIndex(moment(SelectedDate).day() + 1);
  }, [modalIsOpen]);

  useEffect(() => {
    // const now = moment();
    const fetchDocAvailability = async () => {
      const res = await getDoctorAvailability(mapping_id);
      if (res?.status === 200) {
        const api_data: DocAvailability[] = res.data.result.doctor_availability;
        const data = api_data
          .filter((i) => i.day_of_week === index)
          .map((item) => {
            return {
              value: String(item.availability_id),
              label: `${moment(item.start_time, "HH:mm:ss").format(
                "hh:mmA"
              )} - ${moment(item.end_time, "HH:mm:ss").format("hh:mmA")}`,
              data: {
                wait_time: item.wait_time.minutes,
                queue_type: item.queue_type,
              },
              start_time: item.start_time,
            };
          })
          .sort((a, b) => {
            // Sort by ascending order of time
            return moment(a.start_time, "HH:mm:ss").diff(
              moment(b.start_time, "HH:mm:ss")
            );
          });

        setAllSessions(data);
        setDocSession(data && data[0]);
      }
    };
    fetchDocAvailability();
  }, [bookingDate]);

  const handleSlotBooking = async () => {
    if (bookingDate && docSession && booking_id && userData) {
      closeModal();

      if (bookingSlot) {
        const req = {
          last_booking_id: booking_id,
          booked_date: bookingDate,
          booking_session_start_time: String(docSession?.label)?.split(
            " - "
          )[0],
          booking_session_end_time: String(docSession?.label)?.split(" - ")[1],
          booked_slot_start: moment(
            bookingSlot[0].split(" - ")[0],
            "hh:mmA"
          ).format("HH:mm:ss"),
          booked_slot_end: moment(
            bookingSlot[0].split(" - ")[1],
            "hh:mmA"
          ).format("HH:mm:ss"),
          availability_id: String(docSession?.value),
          booking_mode: "followup",
          comments: comments,
          is_confirmed: true,
          added_by: userData.user_id,
          queue_type: queue_type,
        };

        const res = await addFollowUp(req);

        if (res?.status === 201) {
          toast.success(
            `Patient follow up added for ${moment(bookingDate).format(
              "DD MMM YYYY"
            )}!`
          );
          setBookingDate(SelectedDate);
          setCurrentIndex(0);
          console.log(res.data);

          const booked_data = await getBookingListByAvailabilityId(
            String(session?.value),
            SelectedDate
          );
          if (booked_data?.status === 200) {
            setBookings(booked_data.data.result);
          } else setBookings(undefined);
        }
      }
    } else {
      toast.error("Invalid inputs.");
    }
  };

  const handleSessionBooking = async () => {
    if (bookingDate && docSession && booking_id && userData) {
      closeModal();
      const [start, end] = String(docSession?.label).split(" - ");

      const req = {
        last_booking_id: booking_id,
        booked_date: bookingDate,
        booking_session_start_time: moment(start, "hh:mmA").format("HH:mm:ss"),
        booking_session_end_time: moment(end, "hh:mmA").format("HH:mm:ss"),
        booked_slot_start: moment(start, "hh:mmA").format("HH:mm:ss"),
        booked_slot_end: moment(end, "hh:mmA").format("HH:mm:ss"),
        availability_id: String(docSession?.value),
        booking_mode: "followup",
        comments: comments,
        is_confirmed: true,
        added_by: userData.user_id,
        queue_type: queue_type,
      };

      const res = await addFollowUp(req);

      if (res?.status === 201) {
        toast.success(
          `Patient follow up added for ${moment(bookingDate).format(
            "DD MMM YYYY"
          )}!`
        );
        setBookingDate(SelectedDate);
        setCurrentIndex(0);
        console.log(res.data);

        const booked_data = await getBookingListByAvailabilityId(
          String(session?.value),
          SelectedDate
        );
        if (booked_data?.status === 200) {
          setBookings(booked_data.data.result);
        } else setBookings(undefined);
      }
    } else {
      toast.error("Invalid inputs.");
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    queue_type === "Session" || queue_type === "Token"
      ? handleSessionBooking()
      : handleSlotBooking();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      {/* Header */}
      <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
        <p className="text-dark text-md">Follow Up</p>
        <CloseButton
          handleClick={() => {
            closeModal();
            setBookingDate(SelectedDate);
            setCurrentIndex(0);
          }}
        />
      </div>

      {/* Body */}
      <form onSubmit={handleSubmit} className="mb-0">
        <div className="p-0 max-h-96 overflow-y-auto">
          {hospital_id && trialClinics.includes(hospital_id) ? (
            <div className="px-6 py-5 bg-lightGrey flex justify-center items-center">
              <p className="font-semibold inline-block my-5">
                Follow Up is locked in trial.
              </p>
            </div>
          ) : (
            <div className="px-6 py-5 bg-lightGrey">
              <div className="flex flex-row">
                <p className="text-modalText text-sm mb-3">
                  Note: Schedule the patient's follow-up appointment according
                  to the doctor's recommendation.
                </p>
              </div>
              <div className="">
                <p className="font-light text-modalText text-sm mb-1">
                  Comments
                </p>
                <input
                  className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                  placeholder="Add comments"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  maxLength={50}
                />
              </div>
              <div className="mt-3">
                <p className="font-light text-modalText text-sm mb-1 ">
                  Booking For
                </p>
                <div className="flex flex-row">
                  <input
                    className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full mr-2"
                    placeholder="Select Date"
                    type="date"
                    value={bookingDate}
                    min={moment().format("YYYY-MM-DD")}
                    max={moment().add(1, "year").format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setBookingDate(e.target.value);
                      const selectedDate = e.target.value;
                      const dayOfWeek = moment(selectedDate).day() + 1;
                      setIndex(dayOfWeek);
                    }}
                  />
                  {allSessions && allSessions.length > 0 ? (
                    <select
                      className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                      onChange={(e) => {
                        setAllSlots([""]);
                        const label =
                          e.target.options[e.target.selectedIndex].text;
                        const value = e.target.value;
                        setDocSession({ label, value });
                        console.log(
                          allSessions?.filter(
                            (session) => session.value === value
                          )[0].data?.queue_type
                        );
                        setQueue_type(
                          allSessions?.filter(
                            (session) => session.value === value
                          )[0].data?.queue_type
                        );
                      }}
                    >
                      <option disabled hidden>
                        {String(docSession?.label).split(" - ")[0] +
                          " - " +
                          String(docSession?.label).split(" - ")[1]}
                      </option>
                      {allSessions.map((session) => (
                        <option key={session.value} value={session.value}>
                          {String(session?.label).split(" - ")[0] +
                            " - " +
                            String(session?.label).split(" - ")[1]}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <NotFound text="No sessions" />
                  )}
                </div>
              </div>
              {queue_type !== "Session" && queue_type !== "Token" && (
                <div className="flex flex-col w-full my-3">
                  <p className="font-light text-modalText text-sm mb-1">
                    {estimateVisitTime.includes(String(hospital_id))
                      ? "Estimate Visit Time"
                      : "Choose slot timings"}
                  </p>
                  {/* <div className="flex flex-row space-x-3 w-[292px] md:w-[490px] overflow-x-auto"> */}
                  <div>
                    {currentIndex > 0 && (
                      <button
                        type="button"
                        className="bg-white p-2 rounded-lg hover:opacity-70"
                        onClick={showPrevious}
                      >
                        <LeftArrow />
                      </button>
                    )}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3 my-3">
                      {slicedSlots.length !== 1 || slicedSlots[0] !== "" ? (
                        slicedSlots.map((item, index) => (
                          <React.Fragment key={index}>
                            <SelectSlot
                              item={item}
                              setSlot={setBookingSlot}
                              selected={bookingSlot?.includes(item) || false}
                              people={1}
                            />
                          </React.Fragment>
                        ))
                      ) : (
                        <NotFound text="No Slots Available" />
                      )}
                    </div>
                    {currentIndex <= allSlots.length &&
                      (slicedSlots.length !== 1 || slicedSlots[0] !== "") && (
                        <button
                          type="button"
                          className="bg-white p-2 rounded-lg hover:opacity-70"
                          onClick={showNext}
                        >
                          <RightArrow />
                        </button>
                      )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
          <WhiteButton
            name="Cancel"
            handleClick={() => {
              closeModal();
              setBookingDate(SelectedDate);
              setCurrentIndex(0);
            }}
          />
          {allSessions?.length !== 0 &&
            hospital_id &&
            !trialClinics.includes(hospital_id) && (
              <DarkBlueButton name="Save" type="submit" />
            )}
        </div>
      </form>
    </Modal>
  );
};

export default FollowUpModal;
