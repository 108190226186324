import React from "react";
import { useNavigate } from "react-router-dom";
import { useHospDocData } from "../../../lib/contexts/HospitalDoctorContext";

const ViewQueue = ({
  mapping_id,
  style,
  textStyle,
}: {
  mapping_id: string;
  style?: string;
  textStyle?: string;
}) => {
  const navigate = useNavigate();
  const { hospitalID } = useHospDocData();

  return (
    <button
      className={`bg-available text-white px-3 py-2 rounded hover:bg-green-400 ${
        style ? style : ""
      }`}
      onClick={() => {
        navigate(`/${hospitalID}/doctors/doc/${mapping_id}`);
      }}
    >
      <p
        className={`text-sm hidden md:flex truncate ${
          textStyle ? textStyle : ""
        }`}
      >
        View Queue
      </p>
      <p className={`text-sm flex md:hidden ${textStyle ? textStyle : ""}`}>
        Queue
      </p>
    </button>
  );
};

export default ViewQueue;
