import React, { useEffect, useState } from "react";
import Header from "../components/organisms/Header";
import { useUserData } from "../lib/contexts/UserContext";
import { Logout } from "../assets/icons/Icons";
import PasswordModal from "../components/organisms/modals/PasswordModal";
import { modalStyles } from "../assets/styles/modalStyles";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import { getRouteSegment } from "../lib/utils/funcs";

const Settings = () => {
  const id = getRouteSegment(1);
  const { userData, handleLogout } = useUserData();
  const { setHospitalID } = useHospDocData();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setHospitalID(id);
  }, [id]);

  return (
    <div className="flex flex-col w-full">
      <Header header value={"Settings"} />
      <div className="bg-white flex flex-col mt-6 ml-7 mr-11 rounded-lg">
        <div className="flex flex-row justify-between items-center rounded-t-lg border-[1px] border-b-doctorsBorder px-5 py-4">
          <p className="text-dark font-medium">User Credentials</p>
          <button
            className="bg-white hover:bg-onLeave hover:text-white flex flex-row items-center space-x-1 text-onLeave border-[1px] border-onLeave hover:border-red-400 px-5 py-[6px] rounded-[20px]"
            onClick={handleLogout}
          >
            <Logout />
            <p className="text-xs lg:text-base">Logout</p>
          </button>
        </div>
        <div className="rounded-b-lg p-4 border-[1px] border-t-0 border-doctorsBorder space-y-6">
          <div className="flex flex-col md:grid md:grid-cols-12">
            <div className="flex flex-col col-span-4">
              <p className="text-docDetail text-xs mb-[6px]">Email Address</p>
              <p className="text-dark md:text-sm">{userData?.email}</p>
            </div>
            <div className="flex flex-row col-span-4 space-x-5 mt-10 md:mt-0">
              <div className="flex flex-col">
                <p className="text-docDetail text-xs mb-[6px]">Password</p>
                <p className="text-dark md:text-sm">************</p>
              </div>
              <button
                className="bg-editBG hover:bg-queueHover px-4 md:py-0 lg:py-1 rounded-[20px] flex justify-center items-center"
                onClick={() => setVisible(true)}
              >
                <p className="text-white text-sm">Change</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <PasswordModal
        modalIsOpen={visible}
        closeModal={() => setVisible(false)}
        customStyles={modalStyles}
      />
    </div>
  );
};

export default Settings;
