import React, { useState, useEffect } from "react";
import ReactDropdown, { Option } from "react-dropdown";

import AdjacentDates from "../components/atoms/buttons/AdjacentDates";
import DocDetailsCard from "../components/molecules/DocDetailsCard";
import QueueEmpty from "../components/molecules/QueueEmpty";
import AddBookingModal from "../components/organisms/modals/AddBookingModal";

import {
  getDatesAroundToday,
  getDatesFromSunToSat,
  getRouteSegment,
} from "../lib/utils/funcs";
import Header from "../components/organisms/Header";
import Tabs from "../components/molecules/Tabs";
import Table from "../components/organisms/Table";

import { completedHeaders, cancelledHeaders } from "../assets/data/headers";
import { LeftArrow, RightArrow } from "../assets/icons/Icons";
import "../assets/styles/table.css";
import { useParams } from "react-router-dom";
import moment from "moment";
import { modalStyles } from "../assets/styles/modalStyles";
import { toast } from "react-toastify";
import { useInterval } from "../lib/utils/useInterval";
import Loader from "../components/molecules/Loader";
import FollowUpModal from "../components/organisms/modals/FollowUpModal";
import RescheduleModal from "../components/organisms/modals/RescheduleModal";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import { getCookie, setCookie } from "../lib/utils/cookies";
import { usePatientBooking } from "../lib/contexts/PatientBookingContext";
import { DocAvailability } from "../lib/utils/types";
import { hitRefreshToken } from "../lib/apis/user";
import { useUserData } from "../lib/contexts/UserContext";
import {
  getBookingListByAvailabilityId,
  getOnGoing,
} from "../lib/apis/booking";
import { getDoctorAvailability } from "../lib/apis/doctor";
import { estimateVisitTime } from "../lib/utils/constants";

const EachDoctor = () => {
  const id = getRouteSegment(1);
  const accessToken = String(getCookie("accessToken"));
  const refreshToken = String(getCookie("refreshToken"));

  const [week, setWeek] = useState(0);
  const [visible, setVisible] = useState(false);
  const [followUpVisible, setFollowUpVisible] = useState(false);
  const [rescheduleVisible, setRescheduleVisible] = useState(false);
  const [options, setOptions] = useState<Option[]>();
  const [session, setSession] = useState<Option>();
  const [docAvail, setDocAvail] = useState<DocAvailability[]>();
  const [bookingId, setBookingId] = useState<string>();
  const [queue_type, setQueue_type] = useState("");

  const { userData } = useUserData();
  const { hospitalID, setHospitalID, docDetails, setDocDetails } =
    useHospDocData();
  const {
    bookings,
    setBookings,
    SelectedDate,
    setSelectedDate,
    index,
    setIndex,
  } = usePatientBooking();

  const param = useParams();
  const mapping_id = String(param.mapping_id);

  useEffect(() => {
    setHospitalID(id);
  }, [id]);

  const fetchBookingsData = async (id: string) => {
    const booked_data = await getBookingListByAvailabilityId(id, SelectedDate);
    if (booked_data?.status === 200) {
      setBookings(booked_data.data.result);
      console.log("bookings", booked_data.data.result);
    } else setBookings(undefined);
  };

  useEffect(() => {
    const fetchDocAvailability = async () => {
      const res = await getDoctorAvailability(mapping_id);
      if (res?.status === 200) {
        setDocAvail(res.data.result.doctor_availability);
        setDocDetails(res.data.result.doctor_details);
      } else if (res?.status === 401) {
        const refresh_data = await hitRefreshToken(accessToken, refreshToken);
        if (refresh_data?.status === 200) {
          console.log("Refresh");
          setCookie("accessToken", refresh_data.data.result.access_token, 30);
          setCookie("refreshToken", refresh_data.data.result.refresh_token, 30);
          const res = await getDoctorAvailability(mapping_id);
          if (res?.status === 200) {
            setDocAvail(res.data.result.doctor_availability);
            setDocDetails(res.data.result.doctor_details);
          }
        }
      }
    };
    fetchDocAvailability();
  }, [mapping_id, userData]);

  const [data, setData] = useState({
    mapping_id: "",
    date: SelectedDate,
    session: "",
  });

  useEffect(() => {
    setData({
      mapping_id,
      date: SelectedDate,
      session: String(session?.value),
    });
  }, [session, bookings]);

  useEffect(() => {
    if (docAvail !== undefined) {
      const data = docAvail
        .filter((i) => i.day_of_week === index)
        .map((item) => {
          return {
            value: String(item.availability_id),
            label: `${moment(item.start_time, "HH:mm:ss").format(
              "hh:mmA"
            )} - ${moment(item.end_time, "HH:mm:ss").format("hh:mmA")}`,
            data: {
              wait_time: item.wait_time.minutes,
              queue_type: item.queue_type,
            },
            start_time: item.start_time,
          };
        })
        .sort((a, b) => {
          // Sort by ascending order of time
          return moment(a.start_time, "HH:mm:ss").diff(
            moment(b.start_time, "HH:mm:ss")
          );
        });
      const now = moment();
      // const now = moment().set({ hour: 16, minute: 0 });
      const currSession = docAvail
        .filter((i) => i.day_of_week === index)
        .filter((item) => {
          const startTime = moment(item.start_time, "HH:mm:ss").subtract(
            30,
            "minutes"
          ); // Subtract 30 minutes from start time
          const endTime = moment(item.end_time, "HH:mm:ss").add(30, "minutes"); // Add 30 minutes to end time

          return now.isBetween(startTime, endTime); // Check if current time is within the modified range
        })
        .map((item) => {
          return {
            value: String(item.availability_id),
            label: `${moment(item.start_time, "HH:mm:ss").format(
              "hh:mmA"
            )} - ${moment(item.end_time, "HH:mm:ss").format("hh:mmA")}`,
            data: {
              wait_time: item.wait_time.minutes,
              queue_type: item.queue_type,
            },
          };
        });
      setOptions(data);
      fetchBookingsData(
        String(currSession && currSession[0] && currSession[0].value)
      );
      if (currSession.length === 0) {
        setSession(data[0]);
        setQueue_type(data[0]?.data.queue_type);
      } else {
        setSession(currSession && currSession[0]);
        setQueue_type(currSession && currSession[0].data.queue_type);
      }
    }
  }, [, SelectedDate, docAvail]);

  useEffect(() => {
    setSelectedDate(moment().format("YYYY-MM-DD"));
    setIndex(moment().day() + 1);
  }, []);

  useInterval(async () => {
    const sesh = String(session?.value);
    const api_data = await getOnGoing(mapping_id, sesh, SelectedDate);
    const bCount = bookings ? bookings.length : 0;
    if (api_data?.status === 200) {
      const total_bookings = Number(api_data.data.result.total_bookings);
      let apiOnGoing;
      let inclinic_completed_count;
      if (api_data?.status === 200) {
        apiOnGoing = api_data.data.result.curr_booking_id;
        inclinic_completed_count = Number(
          api_data.data.result.inclinic_completed_count
        );
        const onGoingID =
          bookings && bookings?.filter((i) => i.status === 2)[0]
            ? bookings?.filter((i) => i.status === 2)[0].booking_id
            : null;
        const qCount =
          bookings && bookings.filter((i) => i.status === 1 || i.status === 2)
            ? bookings.filter((i) => i.status === 1 || i.status === 2).length
            : 0;
        const cCount =
          bookings && bookings.filter((i) => i.status === 3)
            ? bookings.filter((i) => i.status === 3).length
            : 0;
        console.log("onGoing", onGoingID, apiOnGoing);
        if (onGoingID !== apiOnGoing) {
          console.log("IOT button");
          fetchBookingsData(sesh);
        }
        if (inclinic_completed_count !== qCount + cCount) {
          fetchBookingsData(sesh);
        }
      } else {
        console.log("No onGoing");
      }

      console.log("booked", bCount, total_bookings);
      if (bCount !== total_bookings) {
        fetchBookingsData(sesh);
      }
    } else if (api_data?.status === 401) {
      const refresh_data = await hitRefreshToken(accessToken, refreshToken);
      if (refresh_data?.status === 200) {
        console.log("Refresh");
        setCookie("accessToken", refresh_data.data.result.access_token, 30);
        setCookie("refreshToken", refresh_data.data.result.refresh_token, 30);
      }
    }
  }, 5000);

  const changeOption: ((arg: Option) => void) | undefined = (arg: Option) => {
    setBookings(undefined);
    setSession(arg);
    console.log(
      "queue_type",
      options?.filter((session) => session.value === arg.value)[0].data
        ?.queue_type
    );
    setQueue_type(
      String(
        options?.filter((session) => session.value === arg.value)[0].data
          ?.queue_type
      )
    );
    fetchBookingsData(String(arg.value));
    console.log("session", arg);
  };

  const inclinicHeaders = [
    queue_type === "Token" || queue_type === "Slot_Token"
      ? "Token No."
      : queue_type === "Slot"
      ? "Slot Timings"
      : "",
    "Name",
    "Contact Details",
    "Checked-In At",
    "Status",
  ];

  const bookedHeaders = [
    queue_type === "Token" ? "Token" : "Check-In OTP",
    "Name",
    "Contact Details",
    queue_type !== "Token" && queue_type !== "Session"
      ? estimateVisitTime.includes(String(hospitalID))
        ? "Estimate Visit Time"
        : "Slot Timings"
      : "Scheduled On",
    "Action",
  ];

  const followupHeaders = [
    "Check-In OTP",
    "Name",
    "Contact Details",
    queue_type !== "Token" && queue_type !== "Session"
      ? estimateVisitTime.includes(String(hospitalID))
        ? "Estimate Visit Time"
        : "Slot Timings"
      : "Scheduled On",
    "Action",
  ];

  const tabsConfig = [
    {
      label: "Completed",
      count:
        "(" +
        (bookings?.filter((item) => item.status === 3).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter((item) => item.status === 3).length === 0 ? (
          <QueueEmpty text={"No completed patients"} />
        ) : (
          <div className="max-h-[45vh] md:max-h-[70vh] lg:max-h-[45vh] overflow-y-auto">
            <Table
              type="completed"
              headers={completedHeaders}
              bookings={bookings?.filter((item) => item.status === 3)}
              availability_id={session?.value}
              setVisible={setFollowUpVisible}
              setBookingID={setBookingId}
              queue_type={queue_type}
            />
          </div>
        ),
    },
    {
      label: "Cancelled",
      count:
        "(" +
        (bookings?.filter((item) => item.status === 4).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter((item) => item.status === 4).length === 0 ? (
          <QueueEmpty text={"No cancelled patients"} />
        ) : (
          <div className="max-h-[45vh] md:max-h-[70vh] lg:max-h-[45vh] overflow-y-auto">
            <Table
              type="cancelled"
              headers={cancelledHeaders}
              bookings={bookings?.filter((item) => item.status === 4)}
              availability_id={session?.value}
              queue_type={queue_type}
            />
          </div>
        ),
    },
  ];

  const todayTabsConfig = [
    {
      label: "In Clinic",
      count:
        "(" +
        (bookings
          ?.sort((a, b) => a.token_number - b.token_number)

          .filter((item) => item.status === 1 || item.status === 2).length ||
          "0") +
        ")",
      content:
        bookings === undefined ||
        bookings.filter((item) => item.status === 1 || item.status === 2)
          .length === 0 ? (
          <QueueEmpty text={"No patients in the clinic"} />
        ) : (
          <div className="max-h-[45vh] md:max-h-[70vh] lg:max-h-[45vh] overflow-y-auto">
            <Table
              type="inclinic"
              headers={inclinicHeaders}
              bookings={bookings
                ?.filter((item) => item.status === 1 || item.status === 2)
                .sort((a, b) => b.status - a.status)}
              availability_id={session?.value}
              queue_type={queue_type}
            />
          </div>
        ),
    },
    {
      label: "Booked",
      count:
        "(" +
        (bookings?.filter(
          (item) => item.status === 0 && item.booking_mode === "booking"
        ).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter(
          (item) => item.status === 0 && item.booking_mode === "booking"
        ).length === 0 ? (
          <QueueEmpty text={"No booked patients"} />
        ) : (
          <div className="max-h-[45vh] md:max-h-[70vh] lg:max-h-[45vh] overflow-y-auto">
            <Table
              type="booked"
              headers={bookedHeaders}
              bookings={bookings?.filter(
                (item) => item.status === 0 && item.booking_mode === "booking"
              )}
              availability_id={session?.value}
              setVisible={setRescheduleVisible}
              setBookingID={setBookingId}
              queue_type={queue_type}
            />
          </div>
        ),
    },
    {
      label: "Follow Up",
      count:
        "(" +
        (bookings?.filter(
          (item) => item.status === 0 && item.booking_mode === "followup"
        ).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter(
          (item) => item.status === 0 && item.booking_mode === "followup"
        ).length === 0 ? (
          <QueueEmpty text={"No follow up patients"} />
        ) : (
          <div className="max-h-[45vh] md:max-h-[70vh] lg:max-h-[45vh] overflow-y-auto">
            <Table
              type="followup"
              headers={followupHeaders}
              bookings={bookings?.filter(
                (item) => item.status === 0 && item.booking_mode === "followup"
              )}
              availability_id={session?.value}
              setVisible={setRescheduleVisible}
              setBookingID={setBookingId}
              queue_type={queue_type}
            />
          </div>
        ),
    },
    {
      label: "Completed",
      count:
        "(" +
        (bookings?.filter((item) => item.status === 3).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter((item) => item.status === 3).length === 0 ? (
          <QueueEmpty text={"No completed patients"} />
        ) : (
          <div className="max-h-[45vh] md:max-h-[70vh] lg:max-h-[45vh] overflow-y-auto">
            <Table
              type="completed"
              headers={completedHeaders}
              bookings={bookings?.filter((item) => item.status === 3)}
              availability_id={session?.value}
              setVisible={setFollowUpVisible}
              setBookingID={setBookingId}
              queue_type={queue_type}
            />
          </div>
        ),
    },
    {
      label: "Cancelled",
      count:
        "(" +
        (bookings?.filter((item) => item.status === 4).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter((item) => item.status === 4).length === 0 ? (
          <QueueEmpty text={"No cancelled patients"} />
        ) : (
          <div className="max-h-[45vh] md:max-h-[70vh] lg:max-h-[45vh] overflow-y-auto">
            <Table
              type="cancelled"
              headers={cancelledHeaders}
              bookings={bookings?.filter((item) => item.status === 4)}
              availability_id={session?.value}
              queue_type={queue_type}
            />
          </div>
        ),
    },
  ];

  const futureTabsConfig = [
    {
      label: "Booked",
      count:
        "(" +
        (bookings?.filter(
          (item) => item.status === 0 && item.booking_mode === "booking"
        ).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter(
          (item) => item.status === 0 && item.booking_mode === "booking"
        ).length === 0 ? (
          <QueueEmpty text={"No booked patients"} />
        ) : (
          <div className="max-h-[45vh] md:max-h-[70vh] lg:max-h-[45vh] overflow-y-auto">
            <Table
              type="booked"
              headers={bookedHeaders}
              bookings={bookings?.filter(
                (item) => item.status === 0 && item.booking_mode === "booking"
              )}
              availability_id={session?.value}
              setVisible={setRescheduleVisible}
              setBookingID={setBookingId}
              queue_type={queue_type}
            />
          </div>
        ),
    },
    {
      label: "Follow Up",
      count:
        "(" +
        (bookings?.filter(
          (item) => item.status === 0 && item.booking_mode === "followup"
        ).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter(
          (item) => item.status === 0 && item.booking_mode === "followup"
        ).length === 0 ? (
          <QueueEmpty text={"No follow up patients"} />
        ) : (
          <div className="max-h-[45vh] md:max-h-[70vh] lg:max-h-[45vh] overflow-y-auto">
            <Table
              type="followup"
              headers={followupHeaders}
              bookings={bookings?.filter(
                (item) => item.status === 0 && item.booking_mode === "followup"
              )}
              availability_id={session?.value}
              setVisible={setRescheduleVisible}
              setBookingID={setBookingId}
              queue_type={queue_type}
            />
          </div>
        ),
    },
    {
      label: "Cancelled",
      count:
        "(" +
        (bookings?.filter((item) => item.status === 4).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter((item) => item.status === 4).length === 0 ? (
          <QueueEmpty text={"No cancelled patients"} />
        ) : (
          <div className="max-h-[45vh] md:max-h-[70vh] lg:max-h-[45vh] overflow-y-auto">
            <Table
              type="cancelled"
              headers={cancelledHeaders}
              bookings={bookings?.filter((item) => item.status === 4)}
              availability_id={session?.value}
              queue_type={queue_type}
            />
          </div>
        ),
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <Header docData={docDetails} data={data} />
      <div className="flex flex-col md:flex-row py-6 md:pl-7 md:pr-8">
        <DocDetailsCard docData={docDetails} sessions={options} />
        <div className="bg-white p-2 rounded-lg mt-4 mx-5 md:hidden flex flex-row justify-between items-center">
          {week !== -10 && (
            <button onClick={() => setWeek(week - 5)}>
              <LeftArrow />
            </button>
          )}
          {getDatesAroundToday(week).map((val, index) => (
            <AdjacentDates
              key={index}
              tareek={val.tareek}
              day={val.day}
              date={val.date}
              onPress={() => {
                setSelectedDate(val.date);
                setIndex(moment(val.date).day() + 1);
                console.log(val.date);
              }}
              isSelected={SelectedDate === val.date}
            />
          ))}
          {week !== 10 && (
            <button onClick={() => setWeek(week + 5)}>
              <RightArrow />
            </button>
          )}
        </div>
        <div className="bg-white px-6 py-4 rounded-lg mt-4 md:mt-0 md:ml-5 hidden md:flex flex-col justify-center items-center md:w-2/3">
          <p className="text-dark mb-3 font-medium">
            {moment(SelectedDate).format("MMMM D")}
          </p>
          <div className="flex flex-row w-full justify-between">
            {week !== -2 && (
              <button onClick={() => setWeek(week - 1)}>
                <LeftArrow />
              </button>
            )}
            {getDatesFromSunToSat(week).map((val, index) => (
              <AdjacentDates
                key={index}
                tareek={val.tareek}
                day={val.day}
                date={val.date}
                onPress={() => {
                  setSelectedDate(val.date);
                  setIndex(moment(val.date).day() + 1);
                  console.log(moment(val.date).day() + 1);
                }}
                isSelected={SelectedDate === val.date}
              />
            ))}
            {week !== 2 && (
              <button onClick={() => setWeek(week + 1)}>
                <RightArrow />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-white rounded-lg mx-5 md:ml-7 md:mr-8 md:mb-10 pt-4 text-sm font-medium text-center text-queueText border-b border-sbBorder">
        <Tabs
          tabsConfig={
            moment(SelectedDate, "YYYY-MM-DD").isBefore(moment().startOf("day"))
              ? tabsConfig
              : SelectedDate === moment().format("YYYY-MM-DD")
              ? todayTabsConfig
              : futureTabsConfig
          }
          rightSide={
            <div className="flex justify-end items-center m-3 md:pr-5 md:mt-0 md:mb-[7px]">
              {/* {bookings === undefined ? (
                <p className="text-queueRed mr-3">Queue has not started</p>
              ) : ( */}

              <button
                className={`rounded-lg opacity-80  px-4 py-2 text-white hover:bg-queueHover mr-3
                ${
                  moment(SelectedDate, "YYYY-MM-DD").isBefore(
                    moment().startOf("day")
                  ) || session === undefined
                    ? "bg-queueHover"
                    : "bg-sbTextHover"
                }`}
                onClick={() => {
                  session === undefined
                    ? toast.error("Please select a session")
                    : moment(SelectedDate, "YYYY-MM-DD").isBefore(
                        moment().startOf("day")
                      )
                    ? toast.error("Cannot make bookings for the past.")
                    : setVisible(true);
                }}
              >
                + Add Booking
              </button>

              <ReactDropdown
                options={options ? options : []}
                onChange={changeOption}
                value={session}
                placeholder={"Select a session"}
                controlClassName="!rounded-lg"
              />
            </div>
          }
        />
      </div>
      <AddBookingModal
        modalIsOpen={visible}
        closeModal={() => setVisible(false)}
        customStyles={modalStyles}
        session={session}
        setSession={setSession}
        queue_type={queue_type}
        setQueue_type={setQueue_type}
        options={options}
      />
      <FollowUpModal
        modalIsOpen={followUpVisible}
        closeModal={() => setFollowUpVisible(false)}
        customStyles={modalStyles}
        session={session}
        booking_id={bookingId}
      />
      <RescheduleModal
        modalIsOpen={rescheduleVisible}
        closeModal={() => setRescheduleVisible(false)}
        customStyles={modalStyles}
        session={session}
        booking_id={bookingId}
      />

      {userData === undefined && <Loader />}
    </div>
  );
};

export default EachDoctor;
